import { createSlice } from '@reduxjs/toolkit'
import { TABLE_PAGE_SIZE_OPTIONS } from '../../features/notifications/common/constants'
import { constructPaginationCursor } from '../../features/notifications/common/notification-helpers'
import { NotificationState } from '../../features/notifications/types/common-types'
import { apiSlice } from './api-slice'

export const initialState: NotificationState = {
  message: '',
  startFrom: 0,
  endAt: 0,
  product: 0,
  sender: '',
  type: '',
  readState: 'ALL',
  sortBy: 'CREATED_AT',
  order: 'DESC',
  limit: TABLE_PAGE_SIZE_OPTIONS[0],
  page: 0,
  tableNotifications: {},
  tableCursor: {}, // Cursor for table notifications
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    updateNotificationFilter: (state, action) => {
      return { ...state, ...action.payload }
    },
    updateTableNotifications: (state, action) => {
      return { ...state, tableNotifications: action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.fetchTableNotifications.matchFulfilled,
      (state, action) => {
        const { notifications } = action.payload
        const cursor = constructPaginationCursor(notifications, state.sortBy)
        return {
          ...state,
          tableCursor: cursor,
          tableNotifications: {
            ...state.tableNotifications,
            [action.meta.arg.originalArgs.page]: notifications,
          },
        }
      },
    )
    builder.addMatcher(
      apiSlice.endpoints.patchTableNotifications.matchFulfilled,
      (state, action) => {
        const { id, readState } = action.meta.arg.originalArgs
        const notifications = state.tableNotifications[state.page]
        const updatedNotifications = notifications.map((notification) => {
          if (id.includes(notification.id)) {
            return { ...notification, readState }
          }
          return notification
        })
        return {
          ...state,
          tableNotifications: {
            ...state.tableNotifications,
            [state.page]: updatedNotifications,
          },
        }
      },
    )
  },
})

export const { updateNotificationFilter } = notificationSlice.actions
export default notificationSlice
