export default function AppDrawerIcon({ width = '25', height = '24' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75 6.5C5.99264 6.5 7 5.49264 7 4.25C7 3.00736 5.99264 2 4.75 2C3.50736 2 2.5 3.00736 2.5 4.25C2.5 5.49264 3.50736 6.5 4.75 6.5ZM4.75 14C5.99264 14 7 12.9926 7 11.75C7 10.5074 5.99264 9.5 4.75 9.5C3.50736 9.5 2.5 10.5074 2.5 11.75C2.5 12.9926 3.50736 14 4.75 14ZM7 19.25C7 20.4926 5.99264 21.5 4.75 21.5C3.50736 21.5 2.5 20.4926 2.5 19.25C2.5 18.0074 3.50736 17 4.75 17C5.99264 17 7 18.0074 7 19.25Z"
        fill="#202020"
      />
      <path
        d="M20.25 6.5C21.4926 6.5 22.5 5.49264 22.5 4.25C22.5 3.00736 21.4926 2 20.25 2C19.0074 2 18 3.00736 18 4.25C18 5.49264 19.0074 6.5 20.25 6.5ZM22.5 11.75C22.5 12.9926 21.4926 14 20.25 14C19.0074 14 18 12.9926 18 11.75C18 10.5074 19.0074 9.5 20.25 9.5C21.4926 9.5 22.5 10.5074 22.5 11.75ZM20.25 21.5C21.4926 21.5 22.5 20.4926 22.5 19.25C22.5 18.0074 21.4926 17 20.25 17C19.0074 17 18 18.0074 18 19.25C18 20.4926 19.0074 21.5 20.25 21.5Z"
        fill="#202020"
      />
      <path
        d="M12.75 6.5C13.9926 6.5 15 5.49264 15 4.25C15 3.00736 13.9926 2 12.75 2C11.5074 2 10.5 3.00736 10.5 4.25C10.5 5.49264 11.5074 6.5 12.75 6.5ZM15 11.75C15 12.9926 13.9926 14 12.75 14C11.5074 14 10.5 12.9926 10.5 11.75C10.5 10.5074 11.5074 9.5 12.75 9.5C13.9926 9.5 15 10.5074 15 11.75ZM12.75 21.5C13.9926 21.5 15 20.4926 15 19.25C15 18.0074 13.9926 17 12.75 17C11.5074 17 10.5 18.0074 10.5 19.25C10.5 20.4926 11.5074 21.5 12.75 21.5Z"
        fill="#202020"
      />
    </svg>
  )
}
