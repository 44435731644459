import { Stack, styled, Box, Typography } from '@mui/material'
import { faClock } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StyledChip, TypeTag } from './common'
import { NotificationCenterRow } from '../types/common-types'
import { formatNotificationDisplayTime } from '../common/time-helpers'
import NotificationCenterMsg from './NotificationCenterMsg'
import { NotificationRadioWithToolTip } from './NotificationRadioWithToolTip'
import { useCommon } from '../../../context/commonContext'

interface NotificationCellProps
  extends Omit<NotificationCenterRow, 'id' | 'sender'> {
  onClick?: () => void
}

export default function NotificationCell({
  title,
  createdAt,
  message,
  product,
  type,
  readState = false,
  link,
  linkName,
  onClick,
}: NotificationCellProps) {
  const { productAccess } = useCommon()

  const checkIfMessageEclipsed = () => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (!context) {
      return { link: false, text: false }
    }
    context.font = '400 14px Inter'
    const letterSpacing = 0.25
    const columnWidth = 361 // Same as the width of the message text
    // Measure the link
    const parts = message.split(/(\{\{link\}\})/)
    const substring = parts[0]
    const textBeforeLink = context.measureText(substring)
    const textBeforeLinkWidth =
      textBeforeLink.width + (substring.length - 1) * letterSpacing
    const linkEclipsed = textBeforeLinkWidth >= columnWidth
    // Measure the text
    const text = context.measureText(message)
    const textWidth = text.width + (message.length - 1) * letterSpacing
    const textEclipsed = textWidth > columnWidth
    return { link: linkEclipsed, text: textEclipsed }
  }

  const { link: linkEclipsed, text: textEclipsed } = checkIfMessageEclipsed()

  return (
    <CellContainer>
      <Stack>
        <RowContainer width="540px" overflow="hidden">
          <Typography
            variant={readState ? 'body1' : 'body1bold'}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              lineHeight: '20px',
              wordBreak: 'break-all',
            }}
          >
            {title}
          </Typography>
          <Separator />
          <RowContainer marginRight="4px" flexGrow={1} flexShrink={0}>
            <FontAwesomeIcon
              icon={faClock}
              color="rgba(20, 20, 20, 0.75)"
              width="12px"
              height="11px"
            />
            <Typography
              variant="caption"
              sx={{ color: 'rgba(20, 20, 20, 0.75)', marginLeft: '4px' }}
            >
              {formatNotificationDisplayTime(createdAt)}
            </Typography>
          </RowContainer>
          <NotificationRadioWithToolTip
            readState={readState}
            onClick={onClick}
          />
        </RowContainer>

        <NotificationCenterMsg
          isMessageEclipsed={textEclipsed}
          isLinkEclipsed={linkEclipsed}
          message={message}
          link={link}
          linkName={linkName}
        />

        <RowContainer marginTop="8px">
          <StyledChip
            variant="outlined"
            sx={{
              color: '#FF600F',
              borderColor: '#FF600F',
              marginRight: '4px',
            }}
            label={productAccess[product]}
          />
          <TypeTag label={type} />
        </RowContainer>
      </Stack>
    </CellContainer>
  )
}

const CellContainer = styled(Stack)(() => ({
  width: '600px',
  maxHeight: '124px',
  padding: '0px 24px',
}))

const RowContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
}))

const Separator = styled(Box)(() => ({
  width: 4,
  height: 4,
  backgroundColor: 'black',
  borderRadius: '50%',
  marginLeft: '8px',
  marginRight: '8px',
  flexGrow: 0,
  flexShrink: 0,
}))
