import React, { useState } from 'react'
import {
  ClickAwayListener,
  IconButton,
  Popper,
  Stack,
  Tooltip,
} from '@mui/material'
import { styled } from '@mui/material/styles'

export type ActionItemProps = {
  title: string
  icon: () => JSX.Element
  isActive?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  children?: React.ReactNode
  style?: React.CSSProperties
}

export default function ActionItem({
  title,
  icon,
  isActive,
  onClick,
  children,
  style,
}: ActionItemProps) {
  return (
    <>
      <Tooltip title={title} placement="bottom">
        <IconButton
          color="info"
          onClick={onClick}
          className={isActive ? 'Mui-active' : ''}
          style={style}
        >
          {icon()}
        </IconButton>
      </Tooltip>
      {children}
    </>
  )
}

export type ActionItemWithPopperProps = {
  title: ActionItemProps['title']
  icon: ActionItemProps['icon']
  children: ActionItemProps['children']
}

export function ActionItemWithPopper({
  title,
  icon,
  children,
}: ActionItemWithPopperProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const isActive = Boolean(anchorEl)

  return (
    <ActionItem
      title={title}
      icon={icon}
      isActive={isActive}
      onClick={handleClick}
    >
      <Popper
        open={isActive}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={{ zIndex: 1200 }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-3, 11.5],
            },
          },
        ]}
        popperOptions={{
          strategy: 'fixed',
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null)
          }}
        >
          <Dropdown>{children}</Dropdown>
        </ClickAwayListener>
      </Popper>
    </ActionItem>
  )
}

export type ActionItemWithLinkProps = {
  title: ActionItemProps['title']
  icon: ActionItemProps['icon']
  url: string
}

export function ActionItemWithLink({
  title,
  icon,
  url,
}: ActionItemWithLinkProps) {
  const onClickLink = () => {
    if (url) {
      window.open(url, '_blank')
    }
  }

  return <ActionItem title={title} icon={icon} onClick={onClickLink} />
}

const Dropdown = styled(Stack)(() => ({
  'borderRadius': '8px',
  'boxShadow': 'rgba(21, 21, 18, 0.25) 0px 35px 120px 0',
  'backgroundColor': '#fff',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-10px',
    right: '10px',
    width: '0',
    height: '0',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid #fff',
  },
}))
