import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  Stack,
} from '@mui/material'

import SelectControl from '../../icons/SelectControl'
import SeachIcon from '../../icons/SearchIcon'
import { COLOR } from '../../common/styles'
import {
  DEFAULT_TIMEZONE,
  DEFAULT_TIMEZONE_DISPLAY,
} from '../../common/constants'

// Props interface for the TimezoneSetting component
interface TimezoneSettingProps {
  showSubtitle?: boolean
  selectedTimezone: string
  setSelectedTimezone: (selectedTimezone: string) => void
}

/**
 * Gets the current local timezone.
 * @returns {string} - The formatted date and time string.
 */
function getUserLocalTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

/**
 * Formats the timezone string for display.
 * @param {string} timeZone - The original timezone string.
 * @returns {string} - The formatted timezone string.
 */
const formatTimezone = (timeZone: string, offsetDisplay: 'hour' | 'number') => {
  if (timeZone === DEFAULT_TIMEZONE) {
    return DEFAULT_TIMEZONE_DISPLAY
  }
  const parts = timeZone.split('/')
  const continent = parts[0]
  const city = parts[1] || '' // Default to an empty string if city is undefined

  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeZoneName: 'shortOffset',
  })

  let offset = formatter
    .formatToParts()
    .find((part) => part.type === 'timeZoneName')!
    .value.substring(3)

  if (!offset) {
    offset += '+0'
  }

  if (offsetDisplay === 'hour') offset += ' hours'
  if (offsetDisplay === 'number' && !offset.includes(':')) offset += ':00'

  const sanitizedCity = city.replace(/_/g, ' ')
  return `${sanitizedCity}, ${continent} (UTC ${offset})`
}

const allAvailableTimeZones = Intl.supportedValuesOf('timeZone').map(
  (name) => ({
    value: name,
    label: formatTimezone(name, 'number'),
  }),
)

function TimezoneSetting({
  showSubtitle,
  selectedTimezone,
  setSelectedTimezone,
}: TimezoneSettingProps) {
  // ---- Translation Hooks ----
  const { t } = useTranslation()

  // ---- State Variables ----
  const [searchTerm, setSearchTerm] = useState<string>('')

  // Used to store user's browser timezone
  const [userLocalTimezone] = useState<string>(getUserLocalTimezone) // default to UTC
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleString())

  // ---- Effects ----
  // Update the current time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date().toLocaleString())
    }, 1000)

    return () => clearInterval(intervalId)
  }, [])

  // ---- Handlers ----
  const handleTimezoneClick = (timezone: string) => {
    setSelectedTimezone(timezone)
    setShowSearch(false)
  }

  const toggleSearch = () => {
    setShowSearch((prevShowSearch) => !prevShowSearch)
  }

  const filteredTimeZones = allAvailableTimeZones.filter(({ value }) =>
    value.toLowerCase().includes(searchTerm),
  )

  // ---- JSX Rendering ----
  return (
    <Stack gap="8px">
      {showSubtitle && (
        <Typography color={(theme) => theme.palette.text.secondary}>
          {t('Time zone')}
        </Typography>
      )}
      <SelectedTimezoneBox onClick={toggleSearch}>
        <span>{formatTimezone(selectedTimezone, 'hour')}</span>
        <SelectControl color={COLOR.MAIN_BLACK_50} />
      </SelectedTimezoneBox>
      {showSearch && (
        <TimezoneSearchContent>
          <TimezoneSearchBox>
            <StyledTextField
              placeholder={searchTerm ? '' : t('Search Timezone')}
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={(e) =>
                setSearchTerm(e.target.value.toLocaleLowerCase())
              }
              InputLabelProps={{
                shrink: false,
              }}
              onKeyDown={(e) => {
                e.stopPropagation()
              }}
            />
            <SeachIcon />
          </TimezoneSearchBox>
          <TimezoneOptionsList>
            {/* Pinned Local Timezone */}
            <TimezoneListItemContainer
              key={userLocalTimezone}
              onClick={() => handleTimezoneClick(userLocalTimezone)}
              sx={{
                position: 'sticky',
                zIndex: 1,
                top: 0,
                alignItems: 'center',
                borderBottom: `3px solid ${COLOR.GRAY_BORDER}`,
                borderRadius: 0,
              }}
            >
              <ListItemText
                primary={formatTimezone(userLocalTimezone, 'hour')}
              />
              <LocalTimezoneLabel>
                {t('Your Local Timezone')}
              </LocalTimezoneLabel>
            </TimezoneListItemContainer>
            {filteredTimeZones.map(({ value, label }) => (
              <TimezoneListItemContainer
                key={value}
                onClick={() => handleTimezoneClick(value)}
              >
                <ListItemText primary={label} />
              </TimezoneListItemContainer>
            ))}
          </TimezoneOptionsList>
        </TimezoneSearchContent>
      )}
      <Typography
        variant="caption"
        color={(theme) => theme.palette.text.secondary}
      >
        {t(`Geocomply uses your time zone to provide an accurate display of account
        activity, transactions, notifications, and reminders according to
        location.`)}
      </Typography>
      <LocalDateTimeDisplay>
        {`${t('Current local date and time')}: ${currentTime}`}
      </LocalDateTimeDisplay>
    </Stack>
  )
}

// Container for the search functionality within the modal.
const TimezoneSearchContent = styled('div')({
  display: 'flex',
  padding: 8,
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 3,
  alignSelf: 'stretch',
  borderRadius: 8,
  border: `1px solid var(--border-secondary, ${COLOR.GRAY_BORDER})`,
  background: 'var(--main-white, #fff)',
})

// ---- Textual Components ----

// Informational text providing context about the timezone functionality.
const TimezoneInfoText = styled('div')({
  flex: '1 0 0',
  color: 'var(--text-secondary, rgba(0, 0, 0, 0.6))',
  fontSize: 13,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '135%', // 17.55px
})

// Display for the current local date and time.
const LocalDateTimeDisplay = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  color: 'var(--main-black, #202020)',
  fontSize: 15,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%', // 18px
  marginTop: 16,
})

// ---- Input and Interactive Components ----

// Styled input field for searching timezones.
const StyledTextField = styled(TextField)({
  '&&': {
    'width': '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      border: '1px solid rgba(32, 32, 32, 0.25)',
      background: '#f7f7f7',
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 40px 10px 12px',
    },
    '& .MuiInputLabel-outlined': {
      transition: 'none', // Remove the transition animation
    },
  },
})

// Container for the search input, including any associated icons.
const TimezoneSearchBox = styled('div')({
  position: 'relative',
  width: '100%',
})

// Display box for the currently selected timezone.
const SelectedTimezoneBox = styled('div')({
  'display': 'flex',
  'width': 552,
  'padding': 12,
  'justifyContent': 'flex-end',
  'alignItems': 'center',
  'gap': 8,
  'alignSelf': 'stretch',
  'borderRadius': 8,
  'border': '1px solid rgba(32, 32, 32, 0.25)',
  'background': '#fff',
  'cursor': 'pointer',
  '& > span': {
    flex: '1 0 0',
    color: '#202020',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '120%',
  },
})

// ---- List Components ----

// Container for the list of timezone options.
const TimezoneOptionsList = styled(List)({
  width: 552,
  maxHeight: 200,
  overflowY: 'auto',
  gap: 5,
  marginLeft: '-9px',
  padding: '0 5px',
})

// Individual list item for a timezone option.
const ListItemStyled = styled(ListItem)({
  display: 'flex',
  padding: '8px 10px',
  alignItems: 'flex-start',
  gap: 10,
  alignSelf: 'stretch',
  borderRadius: 6,
  boxSizing: 'border-box',
})

const TimezoneListItemContainer = styled(ListItemStyled)({
  'background': '#fff',
  'display': 'flex',
  'justifyContent': 'space-between',
  '&:hover': {
    backgroundColor: 'var(--main-primary-blue, #3779e4)',
    color: '#fff',
    cursor: 'pointer',
  },
  '&:hover span': {
    color: '#fff',
  },
})

// Label indicating that the timezone is the user's local timezone.
const LocalTimezoneLabel = styled('span')({
  color: '#000',
  fontSize: 12,
  fontStyle: 'italic',
})

export default TimezoneSetting
