import { DateRange, PickersShortcutsItem } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'
import i18n from 'i18next'

export const NOTIFICATION_LOAD_LIMIT = 6
export const DEFAULT_ROW_HEIGHT = 76
export const EXPANDED_ROW_HEIGHT = 152
export const TABLE_PAGE_SIZE_OPTIONS = [20, 40, 60]

const { t } = i18n

export const SHORTCUTS_ITEMS: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: t('time.today'),
    getValue: () => {
      const today = dayjs()
      return [today, today]
    },
  },
  {
    label: t('time.yesterday'),
    getValue: () => {
      const today = dayjs()
      const yesterday = today.subtract(1, 'day')
      return [yesterday, yesterday]
    },
  },
  {
    label: t('time.lastWeek'),
    getValue: () => {
      const today = dayjs()
      return [today.subtract(1, 'week'), today]
    },
  },
  {
    label: t('time.lastTwoWeeks'),
    getValue: () => {
      const today = dayjs()
      return [today.subtract(2, 'week'), today]
    },
  },
  {
    label: t('time.thisMonth'),
    getValue: () => {
      const today = dayjs()
      return [today.startOf('month'), today]
    },
  },
  {
    label: t('time.lastMonth'),
    getValue: () => {
      const today = dayjs()
      const previousMonth = today.subtract(1, 'month')
      return [previousMonth.startOf('month'), previousMonth.endOf('month')]
    },
  },
  {
    label: t('time.lastSixMonths'),
    getValue: () => {
      const today = dayjs()
      return [today.subtract(6, 'month'), today]
    },
  },
  {
    label: t('time.reset'),
    getValue: () => {
      return [null, null]
    },
  },
]

export const SORT_BY_STRING_MAP = {
  CREATED_AT: 'createdAt',
  SENDER: 'sender',
  PRODUCT: 'product',
  TYPE: 'type',
}

export const STREAM_IGNORED_MESSAGE = {
  streamOpened: 'Stream opened',
  keepAlive: 'Keep alive',
}
