const EN = {
  is: 'Is',
  notification: {
    notifications: 'Notifications',
    allNotifications: 'All Notifications',
    allCaughtUp: `That's it! You're all caught up!`,
    refreshNotifications: 'Refresh for the latest notifications',
    showUnreadOnly: 'Only show unread',
    markRead: 'Mark as read',
    markAllRead: 'Mark all as read',
    markUnread: 'Mark as unread',
    viewAllNotifications: 'View all notifications',
    actionType: {
      system: 'System',
      assigned: 'Assigned',
      mentioned: 'Mentioned',
      informational: 'Informational',
      alert: 'Alert',
      action_required: 'Action Required',
      reminder: 'Reminder',
    },
  },
  time: {
    today: 'Today',
    yesterday: 'Yesterday',
    lastWeek: 'Last week',
    lastTwoWeeks: 'Last 2 weeks',
    thisMonth: 'This month',
    lastMonth: 'Previous month',
    lastSixMonths: 'Last 6 months',
    reset: 'Reset',
  },
  table: {
    headerFilterLabel: {
      createdAt: 'createdAt',
      sender: 'sender',
      product: 'product',
      type: 'type',
    },
    selectAll: 'Select All',
  },
}

export default EN
