import moment from 'moment'
import { QuickSelectTimeRange } from '../types/CalendarTypes'

const startOfCurrentMonth = moment().startOf('month')
const startOfPreviousMonth = moment()
  .subtract(1, 'months')
  .startOf('month')
  .startOf('day')
const endOfPreviousMonth = moment()
  .subtract(1, 'months')
  .endOf('month')
  .startOf('day')
export const FIRST_EPOCH_DATE = moment('19700101').toDate()

export const FormatWithTime = 'MMM DD, YYYY HH:mm'
export const FormatWithoutTime = 'MMM DD, YYYY'
export const ISOStringNoTimeZone = 'YYYY-MM-DD HH:mm:ss.SSS'
export const DisplayTimeFormat = 'YYYY-MM-DD HH:mm:ss'

export const QuickSelectOptions: Record<string, QuickSelectTimeRange> = {
  // Minutes
  LAST_15_MINUTES: {
    label: 'Last 15 minutes',
    start: 0,
    end: 0,
    minute: -15,
    useExactTime: true,
  },
  LAST_1_HOUR: {
    label: 'Last 1 hour',
    start: 0,
    end: 0,
    minute: -60,
    useExactTime: true,
  },
  LAST_24_HOURS: {
    label: 'Last 24 hours',
    start: 0,
    end: 0,
    minute: -60 * 24,
    useExactTime: true,
  },
  // Days
  ONE_DAY: {
    label: '1 Day',
    start: 0,
    end: 1,
    minute: 0,
    useExactTime: true,
  },
  TODAY: {
    label: 'Today',
    start: 0,
    end: 0,
    minute: 0,
  },
  YESTERDAY: {
    label: 'Yesterday',
    start: -1,
    end: -1,
    minute: 0,
  },
  // Weeks
  ONE_WEEK: {
    label: '1 Week',
    start: 0,
    end: 7,
    minute: 0,
    useExactTime: true,
  },
  THIS_WEEK: {
    label: 'This week',
    start: -moment().day() + 1,
    end: 0,
    minute: 0,
  },
  THIS_WEEK_FROM_SUNDAY: {
    label: 'This week',
    start: -moment().day(),
    end: 0,
    minute: 0,
  },
  LAST_WEEK: {
    label: 'Last week',
    start: -7,
    end: 0,
    minute: 0,
  },
  LAST_7_DAYS: {
    label: 'Last 7 days',
    start: -7,
    end: 0,
    minute: 0,
  },
  LAST_2_WEEKS: {
    label: 'Last 2 weeks',
    start: -14,
    end: 0,
    minute: 0,
  },
  ONE_MONTH: {
    label: '1 Month',
    start: 0,
    end: moment().add(1, 'months').diff(moment(), 'days'),
    minute: 0,
    useExactTime: true,
  },
  SIX_MONTHS: {
    label: '6 Months',
    start: 0,
    end: moment().add(6, 'months').diff(moment(), 'days'),
    minute: 0,
  },
  // Month
  THIS_MONTH: {
    label: 'This month',
    start: startOfCurrentMonth.diff(moment(), 'days'),
    end: 0,
    minute: 0,
  },
  PREVIOUS_MONTH: {
    label: 'Previous month',
    start: startOfPreviousMonth.diff(moment(), 'days'),
    end: endOfPreviousMonth.diff(moment(), 'days'),
    minute: 0,
  },
  LAST_MONTH: {
    label: 'Last month',
    start: startOfPreviousMonth.diff(moment(), 'days'),
    end: endOfPreviousMonth.diff(moment(), 'days'),
    minute: 0,
  },
  PREV_MONTH: {
    label: 'Previous month',
    start: startOfPreviousMonth.diff(moment(), 'days'),
    end: endOfPreviousMonth.diff(moment(), 'days'),
    minute: 0,
  },
  TWO_MONTHS: {
    label: '2 months',
    start: moment().subtract(2, 'months').diff(moment(), 'days'),
    end: 0,
    minute: 0,
  },
  FOUR_MONTHS: {
    label: '4 months',
    start: moment().subtract(4, 'months').diff(moment(), 'days'),
    end: 0,
    minute: 0,
  },
  LAST_6_MONTHS: {
    label: 'Last 6 months',
    start: moment().subtract(6, 'months').diff(moment(), 'days'),
    end: 0,
    minute: 0,
  },
  // Years
  ONE_YEAR: {
    label: '1 year',
    start: 0,
    end: moment().add(1, 'years').diff(moment(), 'days'),
    minute: 0,
    useExactTime: true,
  },
  LAST_YEAR: {
    label: 'Last year',
    start: moment().subtract(1, 'years').diff(moment(), 'days'),
    end: 0,
    minute: 0,
  },
  // All Time
  ALL_TIME: {
    label: 'All time',
    start: moment(FIRST_EPOCH_DATE).diff(moment(), 'days'),
    end: 0,
    minute: 0,
  },
  // const MINIMUM_DATE = moment('20220101').toDate()
  SINCE_2022: {
    label: 'All time',
    start: moment('20220101').diff(moment(), 'days'),
    end: 0,
    minute: 0,
  },
}

export const ListingPageQuickSelectOptions: [
  QuickSelectTimeRange[],
  QuickSelectTimeRange[],
] = [
  [
    QuickSelectOptions.LAST_15_MINUTES,
    QuickSelectOptions.LAST_1_HOUR,
    QuickSelectOptions.LAST_24_HOURS,
    QuickSelectOptions.YESTERDAY,
  ],
  [
    QuickSelectOptions.LAST_WEEK,
    QuickSelectOptions.LAST_2_WEEKS,
    QuickSelectOptions.THIS_MONTH,
    QuickSelectOptions.PREVIOUS_MONTH,
  ],
]

export const ProfilePageQuickSelectOptions: [
  QuickSelectTimeRange[],
  QuickSelectTimeRange[],
] = [
  [
    QuickSelectOptions.TODAY,
    QuickSelectOptions.YESTERDAY,
    QuickSelectOptions.LAST_WEEK,
    QuickSelectOptions.LAST_2_WEEKS,
  ],
  [
    QuickSelectOptions.THIS_MONTH,
    QuickSelectOptions.PREVIOUS_MONTH,
    QuickSelectOptions.LAST_6_MONTHS,
    QuickSelectOptions.LAST_YEAR,
  ],
]

export const BlockMgmtFilterQuickSelectOptions: [
  QuickSelectTimeRange[],
  QuickSelectTimeRange[],
] = [
  [
    QuickSelectOptions.TODAY,
    QuickSelectOptions.YESTERDAY,
    QuickSelectOptions.LAST_WEEK,
    QuickSelectOptions.LAST_2_WEEKS,
  ],
  [
    QuickSelectOptions.THIS_MONTH,
    QuickSelectOptions.PREVIOUS_MONTH,
    QuickSelectOptions.LAST_6_MONTHS,
    QuickSelectOptions.ALL_TIME,
  ],
]

export const BlockQuickSelectOptions: [
  QuickSelectTimeRange[],
  QuickSelectTimeRange[],
] = [
  [
    QuickSelectOptions.ONE_DAY,
    QuickSelectOptions.ONE_WEEK,
    QuickSelectOptions.ONE_MONTH,
  ],
  [QuickSelectOptions.SIX_MONTHS, QuickSelectOptions.ONE_YEAR],
]

export const PowerMapQuickSelectOptions: [
  QuickSelectTimeRange[],
  QuickSelectTimeRange[],
] = [
  [
    QuickSelectOptions.LAST_15_MINUTES,
    QuickSelectOptions.LAST_24_HOURS,
    QuickSelectOptions.LAST_WEEK,
    QuickSelectOptions.LAST_2_WEEKS,
  ],
  [
    QuickSelectOptions.THIS_MONTH,
    QuickSelectOptions.PREVIOUS_MONTH,
    QuickSelectOptions.TWO_MONTHS,
    QuickSelectOptions.FOUR_MONTHS,
  ],
]

