// @include: ./ThemeExtension.d.ts
import { createTheme, CssBaseline, Theme, ThemeProvider } from '@mui/material'
import type {} from '@mui/x-data-grid/themeAugmentation'
import { COLOR } from '../common/styles'
import Checked from '../icons/Checked'
import { WarningDefault, WarningError } from '../icons/Warning.stories'
import CheckBoxOff from './icons/checkbox/CheckboxOff'
import CheckBoxOn from './icons/checkbox/CheckboxOn'
import RadioOff from './icons/radio/RadioOff'
import RadioOn from './icons/radio/RadioOn'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    buttonSmall: React.CSSProperties
    body1bold: React.CSSProperties
    body2bold: React.CSSProperties
    body3: React.CSSProperties
    bodyBig: React.CSSProperties
  }
  interface TypographyVariantsOptions {
    buttonSmall?: React.CSSProperties
    body1bold?: React.CSSProperties
    body2bold?: React.CSSProperties
    body3?: React.CSSProperties
    bodyBig?: React.CSSProperties
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    buttonSmall: true
    body1bold: true
    body2bold: true
    body3: true
    bodyBig: true
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#3779E4',
      light: '#E1F1FF',
    },
    text: {
      primary: '#202020',
      secondary: '#00000099',
    },
    error: { main: '#F2466F' },
    warning: { main: '#FFB236' },
    success: { main: '#1CA031' },
  },
  // Note: MUI breakpoint is said to be inclusive, but for some reason it is not, so we add + 1 to include the breakpoint
  breakpoints: {
    values: {
      xs: 0,
      sm: 834 + 1, // vertical tablet
      md: 1194 + 1, // hozitonal tablet
      lg: 1400 + 1,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'Inter',
    allVariants: {
      color: COLOR.TEXT_LIGHT,
    },
    h1: {
      'fontSize': '60px',
      'fontWeight': 300,
      'lineHeight': '60px',
      'letterSpacing': '-0.5px',
      '@media (max-width:1194px)': {
        fontSize: '48px',
        fontWeight: 400,
        lineHeight: '48px',
      },
    },
    h2: {
      'fontSize': '48px',
      'fontWeight': 400,
      'lineHeight': '56px',
      '@media (max-width:1194px)': {
        fontSize: '32px',
        fontWeight: 400,
        lineHeight: '40px',
        letterSpacing: '0.25px',
      },
    },
    h3: {
      'fontSize': '32px',
      'fontWeight': 600,
      'lineHeight': '40px',
      'letterSpacing': '0.25px',
      '@media (max-width:1194px)': {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '32px',
      },
    },
    h4: {
      'fontSize': '24px',
      'fontWeight': 700,
      'lineHeight': '32px',
      '@media (max-width:1194px)': {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '28px',
        letterSpacing: '0.15px',
      },
    },
    h5: {
      'fontSize': '20px',
      'fontWeight': 700,
      'lineHeight': '28px',
      'letterSpacing': '0.15px',
      '@media (max-width:1194px)': {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '28px',
        letterSpacing: '0.15px',
      },
    },
    h6: {
      'fontSize': '18px',
      'fontWeight': 700,
      'lineHeight': '28px',
      'letterSpacing': '0.15px',
      '@media (max-width:1194px)': {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0.15px',
      },
    },
    subtitle1: {
      'fontSize': '16px',
      'fontWeight': 400,
      'lineHeight': '24px',
      'letterSpacing': '0.25px',
      '@media (max-width:1194px)': {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.25px',
      },
    },
    subtitle2: {
      'fontSize': '14px',
      'fontWeight': 500,
      'lineHeight': '20px',
      'letterSpacing': '0.25px',
      '@media (max-width:1194px)': {
        fontSize: '10px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '0.25px',
      },
    },
    bodyBig: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '19px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    body1bold: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0.25px',
    },
    body2: {
      'fontSize': '14px',
      'fontWeight': 400,
      'lineHeight': '22px',
      'letterSpacing': '0.25px',
      '@media (max-width:1194px)': {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.25px',
      },
    },
    body2bold: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },

    body3: {
      'fontSize': '14px',
      'fontWeight': 500,
      'lineHeight': '20px',
      'letterSpacing': '0.25px',
      // @ts-ignore
      '@media (max-width:1194px)': {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0.25px',
      },
    },
    button: {
      textTransform: 'none',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0.3px',
    },
    buttonSmall: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0.3px',
      textTransform: 'none',
    },
    caption: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.4px',
    },
    overline: {
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-row': {
            '&:hover': {
              backgroundColor: COLOR.WHITE_SMOKE,
            },
            '&.row--warning': {
              '&:hover': {
                backgroundColor: 'rgba(242, 70, 111, 0.05)',
              },
            },
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '0.25px',
          },
          '& .MuiLink-root': {
            overflowWrap: 'anywhere',
          },
          '& .MuiButtonBase-root': {
            'background': 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& svg path': {
              fill: '#0000008F',
            },
          },
          // To enable resizing, make sure columnSeparator is displayed
          '& .MuiDataGrid-root .header--first, & .MuiDataGrid-root .cell--first':
            {
              paddingLeft: '24px',
            },
          '& .MuiDataGrid-cellContent': {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
            letterSpacing: '0.25px',
          },
          '& .MuiTablePagination-select': {
            'background': 'transparent',
            'color': '#202020 !important',
            'alignItems': 'flex-end !important',
            '&:focus': {
              background: 'transparent',
            },
          },
          '& .MuiTablePagination-selectIcon': {
            'left': '0',
            'marginLeft': '18px',
            ' path': {
              fill: '#0000008F',
            },
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          'padding': '12px',
          'boxShadow': 'none',
          'borderRadius': '8px',
          'gap': '2px',
          'height': '42px',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        startIcon: {
          marginRight: '5px',
          marginLeft: 'unset',
        },
        containedPrimary: {
          'background': 'linear-gradient(180deg, #3982F7 0%, #286CDA 100%)',
          '&:active': {
            background: COLOR.BTN_BLUE_PRIMARY_ACTIVE,
          },
          '&:hover': {
            transition: 'unset',
            background: COLOR.BTN_BLUE_PRIMARY_ACTIVE,
          },
          '&:disabled': {
            background: 'rgba(32, 32, 32, 0.25)',
            color: 'rgba(255, 255, 255, 1)',
          },
          '& svg path': {
            fill: '#fff',
          },
        },
        containedSecondary: {
          'background': 'linear-gradient(180deg, #3982F7 0%, #286CDA 100%)',
          '&:active': {
            background: COLOR.BTN_BLUE_PRIMARY_ACTIVE,
          },
          '&:hover': {
            transition: 'unset',
            background: COLOR.BTN_BLUE_PRIMARY_ACTIVE,
          },
          '&:disabled': {
            background: 'rgba(32, 32, 32, 0.25)',
            color: 'rgba(255, 255, 255, 1)',
          },
          '& svg path': {
            fill: '#fff',
          },
        },
        containedError: {
          '& svg path': {
            fill: '#fff',
          },
        },
        outlined: {
          'border': '1px solid rgba(32, 32, 32, 0.25)',
          'color': '#202020',
          'padding': '12px',
          '&:hover': {
            border: '1px solid rgba(32, 32, 32, 0.25)',
            backgroundColor: COLOR.CONCRETE,
            transition: 'unset',
          },
          '&:active': {
            border: '1px solid rgba(32, 32, 32, 0.25)',
            backgroundColor: COLOR.CONCRETE,
          },
        },
        text: {
          'color': '#202020',
          'padding': '12px',
          '&:hover': {
            backgroundColor: COLOR.CONCRETE,
            transition: 'unset',
          },
          '&:active': {
            backgroundColor: COLOR.CONCRETE,
          },
        },
        textSecondary: {
          'color': '#202020',
          'padding': '12px',
          '&:hover': {
            backgroundColor: COLOR.CONCRETE,
            transition: 'unset',
          },
          '&:active': {
            backgroundColor: COLOR.CONCRETE,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          'background': 'linear-gradient(180deg, #3982F7 0%, #286CDA 100%)',
          'width': '42px',
          'height': '42px',
          'borderRadius': '8px',
          '& svg path': {
            fill: '#fff',
          },
          '&:active svg path': {
            fill: '#ccc',
          },
        },
        colorSecondary: {
          'background': '#F3F3F3',
          '& svg path': {
            fill: '#202020',
          },
          'border': '1px solid rgba(32, 32, 32, 0.25);',
          '&:active svg path': {
            fill: '#202020',
          },
        },
        colorInfo: {
          'background': '#F3F3F3',
          '& svg path': {
            fill: '#202020',
          },
          '&:active svg path': {
            fill: '#202020',
          },
        },
        colorInherit: {
          'background': 'transparent',
          '& svg path': {
            fill: '#202020',
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckBoxOff />,
        checkedIcon: <CheckBoxOn />,
      },
    },
    MuiRadio: {
      defaultProps: {
        icon: <RadioOff />,
        checkedIcon: <RadioOn />,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-input': {
            'color': '#202020',
            'height': '42px',
            'boxSizing': 'border-box',
            '&::placeholder': {
              color: `${COLOR.BLACK_50}`,
              opacity: 'unset',
            },
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            borderColor: COLOR.BLACK_50,
            borderWidth: '1px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${COLOR.BLACK_25}`,
            borderRadius: '8px',
          },
          '& .MuiFilledInput-underline': {
            'height': '42px',
            'borderRadius': '8px',
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'center',
            'border': '1px solid transparent',
            '& .MuiInputBase-input': {
              paddingTop: '0',
              paddingBottom: '0',
            },
            '&::before': {
              display: 'none',
            },
            '&::after': {
              display: 'none',
            },
            '&.Mui-focused': {
              border: `1px solid ${COLOR.BLACK_50}`,
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: `${COLOR.BLACK_50}!important`,
              borderWidth: '1px!important',
            },
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: `${COLOR.BLACK_50}!important`,
            },
          },
          '&.MuiInputBase-colorPrimary.MuiOutlinedInput-root': {
            borderRadius: '8px',
            height: '42px',
            fontSize: '15px',
          },
          '&.MuiSelect-root .MuiCheckbox-root': {
            display: 'none',
          },
          '& .MuiSelect-select svg, & .MuiSelect-select + input + svg': {
            marginRight: 8,
          },
          '&.MuiInput-underline': {
            'alignItems': 'center',
            '&:before, &:after': {
              display: 'none',
            },
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        className: 'MuiSelect-root',
      },
      styleOverrides: {
        outlined: {},
        icon: {
          color: '#fff',
        },
        select: {
          '&.MuiSelect-standard': {
            color: '#3779E4',
            alignItems: 'center',
            display: 'flex',
          },
          '&.MuiSelect-select': {
            'padding': '0',
            'borderRadius': '8px',
            'alignItems': 'center',
            'display': 'flex',
            '& svg': {
              margin: '0',
            },
          },
          '&.Mui-focused': {
            border: '1px solid rgba(20, 20, 20, 0.5)',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          'color': '#202020',
          'textDecoration': 'underline',
          'textDecorationColor': 'rgba(57, 130, 247, 0.25)',
          'textUnderlineOffset': '3px',
          '&:hover': {
            textDecorationColor: 'rgba(57, 130, 247, 0.25)',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          'height': '34px',
          'paddingTop': '9px',
          'paddingBottom': '9px',

          'fontSize': '14px',
          'lineHeight': '20px',
          'letterSpacing': '0.25px',

          '&:hover': {
            '.MuiCheckbox-root': {
              '& svg': {
                '& rect': {
                  fill: '#ffff',
                },
                '& path': {
                  fill: '#3779E4',
                },
              },
            },
            '.MuiBox-root': {
              '& svg': {
                '& path': {
                  fill: '#ffff',
                },
              },
            },
            '.colorText': {
              color: '#fff',
              opacity: 0.5,
            },
            '.colorRelatedID': {
              color: '#fff',
            },
          },
          '&:hover, &.Mui-selected, &.Mui-selected:hover': {
            'background': '#3779E4',
            'borderRadius': '6px',
            'color': '#fff',

            '& .MuiTypography-root': {
              color: '#fff',
            },
          },
          '&.Mui-selected': {
            background: 'white',
            color: 'black',
          },
          '& .MuiCheckbox-root ': {
            padding: 0,
            paddingLeft: 0,
            paddingRight: 6,
            paddingY: 0,
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        elevation8: {
          boxShadow: '0px 35px 120px rgba(21, 21, 18, 0.25);',
          borderRadius: '8px',
          minWidth: '255px',
          padding: '3px',
          overflow: 'visible',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          'paddingBottom': '0px',
          'paddingTop': '0px',
          'gap': '3px',
          'display': 'flex',
          'flexDirection': 'column',
          'borderRadius': '8px',

          '& .MuiMenuItem-root': {
            padding: '8px 10px',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          'height': '36px',
          'padding': '13px',
          'overflow': 'visible',
          '& .Mui-checked': {
            'color': '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
            },
          },
          '& .Mui-checked.MuiSwitch-switchBase + .MuiSwitch-track': {
            background: '#1ED5C9',
            opacity: 1,
          },
        },
        thumb: {
          boxShadow: '-4.95385px 6.256px 18.5982px rgba(17, 28, 32, 0.17);',
          color: '#fff',
        },
        track: {
          background: '#E1E1E1',
          opacity: '1',
          height: '11px',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          'background': '#041532',
          'borderRadius': '8px',
          'padding': '8px',
          // body2
          'fontSize': '14px',
          'fontWeight': 400,
          'lineHeight': '22px',
          'letterSpacing': '0.25px',
          '@media (max-width:1194px)': {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.25px',
          },
        },
        arrow: {
          color: '#041532',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          'background': '#fff',
          'boxShadow': '-4.95385px 6.256px 18.5982px rgba(17, 28, 32, 0.17);',
          '&::before': {
            display: 'none',
          },
        },
        track: {
          background: '#1ED5C9',
          height: '10px',
          border: 'none',
        },
        rail: {
          background: '#AFAFAF',
          height: '10px',
          opacity: 0.34,
        },
        valueLabel: {
          background: '#041532',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          borderRadius: '12px',
          backgroundColor: '#fff',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '15px 15px 11px 15px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          'padding': '16px 15px',
          '&:last-child': {
            paddingBottom: '16px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // body2
          'fontSize': '14px',
          'fontWeight': 400,
          'lineHeight': '22px',
          'letterSpacing': '0.25px',
          '@media (max-width:1194px)': {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.25px',
          },
          'color': 'rgba(32, 32, 32, 0.75);',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          background: '#F7F7F7',
          border: '1px solid #F7F7F7',
          cursor: 'pointer',
        },
        colorPrimary: {
          background: '#3779E4',
          border: '1px solid #3779E4',
        },
        label: {
          padding: '8px 10px',
          fontSize: '14px',
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          success: <Checked color={COLOR.GREEN_MINT} height="26" width="26" />,
          error: <WarningError color="#DF325C" height="26" width="26" />,
          warning: <WarningDefault />,
        },
      },
      styleOverrides: {
        root: {
          borderRadius: '10px',
          padding: '10px 15px',
        },
        standardInfo: {
          background: 'rgba(239, 247, 255, 1)',
        },
        action: {
          '& .MuiIconButton-root': {
            background: 'transparent',
            marginLeft: '-15px',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          gap: '8px',
        },
        flexContainer: {
          gap: '20px',
          borderBottom: '1px solid #E1E1E1',
          width: 'auto',
        },
        scroller: {
          width: 'auto',
          flex: '0 0 auto',
          WebkitFlex: '0 0 auto',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          'padding': '0 0',
          'minWidth': 'auto',
          // button small. I used button
          'fontSize': '14px',
          'fontWeight': 600,
          'lineHeight': '24px',
          'letterSpacing': '0.3px',

          'color': '#202020BF',
          '& svg path': {
            fill: '#202020BF',
          },
          '&.Mui-selected': {
            'fontWeight': 600,
            '& svg path': {
              fill: '#3779E4',
            },
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: { marginLeft: '100px' },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          ul: {
            flexWrap: 'nowrap',
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          'borderRadius': '8px',
          'padding': '20px 0',
          '&.Mui-selected': {
            backgroundColor: '#E1F1FF',
            fontWeight: 700,
          },
          '&:hover': {
            backgroundColor: '#F7F7F7',
          },
        },
        ellipsis: {
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
  },
})

interface Props {
  children: React.ReactNode
}

export default function UBOThemeProvider({ children }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

// Todo: This is a temporary solution until we have design library finalized.
// The inner theme some of the components will be leverage as the UBOThemeProvider overwritten some wanted default stylings
export const createInnerTheme = (theme: Theme) =>
  createTheme({
    typography: { ...theme.typography },
    components: {
      MuiTooltip: { ...theme?.components?.MuiTooltip },
    },
  })
