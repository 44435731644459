import { styled, Theme, ThemeProvider } from '@mui/material'
import {
  DataGridPro,
  GridApiPro,
  GridCallbackDetails,
  GridColumnHeaderParams,
  GridFeatureMode,
  GridPaginationModel,
  GridRowHeightParams,
  GridRowParams,
  GridSortModel,
  GridValidRowModel,
  MuiEvent,
  GridFilterModel,
  GridSlotProps,
} from '@mui/x-data-grid-pro'
import { createInnerTheme } from '../UBOThemeProvider'
import React, {
  JSXElementConstructor,
  MutableRefObject,
  ReactNode,
} from 'react'
import { v4 as uuidv4 } from 'uuid'

interface DataGridProTableProps {
  rows: any
  cols: any
  apiRef: MutableRefObject<GridApiPro>
  onRowClick?: any
  initialState?: Record<any, any>
  getRowHeight?: (
    params: GridRowHeightParams,
  ) => number | 'auto' | null | undefined
  onColumnHeaderClick?: (
    params: GridColumnHeaderParams,
    event: MuiEvent<React.MouseEvent>,
  ) => void
  headerFilters?: boolean
  loading?: boolean
  filterMode?: GridFeatureMode
  onFilterModelChange?: (
    model: GridFilterModel,
    details: GridCallbackDetails<'filter'>,
  ) => void
  filterDebounceMs?: number
  rowCount?: number
  disableColumnFilter?: boolean
  pagination?: boolean
  paginationMode?: GridFeatureMode
  pageSizeOptions?: number[]
  paginationModel?: GridPaginationModel
  onPaginationModelChange?: (
    model: GridPaginationModel,
    details: GridCallbackDetails<any>,
  ) => void
  sortingMode?: GridFeatureMode
  sortModel?: GridSortModel
  onSortModelChange?: (
    model: GridSortModel,
    details: GridCallbackDetails<any>,
  ) => void
  checkboxSelection?: boolean
  checkboxSelectionVisibleOnly?: boolean
  getDetailPanelHeight?: (params: any) => number | 'auto'
  getDetailPanelContent?:
    | ((params: GridRowParams<GridValidRowModel>) => ReactNode)
    | undefined
  autoHeight?: boolean
  filterModel?: any
  noRowsOverlay?: JSXElementConstructor<GridSlotProps['noRowsOverlay']>
  noResultsOverlay?: JSXElementConstructor<GridSlotProps['noResultsOverlay']>
  disableColumnSorting?: boolean
  columnHeaderHeight?: number
  headerFilterHeight?: number
  additionalGridProps?: Record<any, any> // For additional customization
}

export default function DataGridProTable({
  rows,
  cols,
  apiRef,
  initialState,
  onRowClick,
  getRowHeight,
  onColumnHeaderClick,
  headerFilters = false,
  loading,
  filterMode = 'server',
  onFilterModelChange,
  filterDebounceMs,
  rowCount,
  disableColumnFilter = true,
  pagination,
  paginationMode,
  pageSizeOptions,
  paginationModel,
  onPaginationModelChange,
  sortingMode,
  sortModel,
  onSortModelChange,
  checkboxSelection,
  checkboxSelectionVisibleOnly,
  getDetailPanelHeight,
  getDetailPanelContent,
  autoHeight,
  filterModel,
  additionalGridProps,
  noRowsOverlay,
  noResultsOverlay,
  disableColumnSorting,
  columnHeaderHeight = 56, // Suppress Nan Error when unprovided
  headerFilterHeight,
}: DataGridProTableProps) {
  return (
    <ThemeProvider theme={(theme: Theme) => createInnerTheme(theme)}>
      <StyledDataGridPro
        onRowClick={onRowClick}
        filterModel={filterModel}
        apiRef={apiRef}
        // Initial State
        initialState={initialState}
        // Rows
        rows={rows}
        getRowHeight={getRowHeight}
        // Columns
        columns={cols}
        // Column Header
        onColumnHeaderClick={onColumnHeaderClick}
        // Server Side Action Loading
        loading={loading}
        // Header Filters
        headerFilters={headerFilters}
        slots={{
          headerFilterMenu: null,
          noRowsOverlay,
          noResultsOverlay,
        }}
        // Server Side Filtering
        filterMode={filterMode}
        onFilterModelChange={onFilterModelChange}
        filterDebounceMs={filterDebounceMs}
        // Server Side Pagination
        rowCount={rowCount}
        pagination={pagination}
        pageSizeOptions={pageSizeOptions}
        paginationMode={paginationMode}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        // Server Side Sorting
        sortingOrder={['asc', 'desc']}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        // Disable Options
        disableColumnMenu
        disableColumnResize
        disableColumnReorder
        disableColumnFilter={disableColumnFilter}
        disableRowSelectionOnClick
        checkboxSelection={checkboxSelection}
        checkboxSelectionVisibleOnly={checkboxSelectionVisibleOnly}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        autoHeight={autoHeight}
        hideFooterRowCount={!pagination}
        getRowId={() => uuidv4()}
        disableColumnSorting={disableColumnSorting}
        columnHeaderHeight={columnHeaderHeight}
        headerFilterHeight={headerFilterHeight}
        // Additional Grid Customization
        {...additionalGridProps}
      />
    </ThemeProvider>
  )
}

const StyledDataGridPro = styled(DataGridPro)({
  '& .MuiDataGrid-overlayWrapper': {
    zIndex: 1,
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },

  '&.MuiDataGrid-root': {
    borderTop: 'none',
    borderBottom: 'none',
    borderRadius: '0px',
  },

  '& .MuiDataGrid-headerFilterRow .MuiDataGrid-columnHeader .MuiTextField-root':
    {
      'flex': 1,
      '& label, .MuiInputBase-input': {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0.25px',
      },
    },

  // Column Header
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    cursor: 'default',
  },

  // Action Cell
  '& .MuiDataGrid-actionsCell': {
    alignSelf: 'flex-start',
  },

  // Footer
  '& .MuiDataGrid-footerContainer': {
    minHeight: '48px',
    height: '48px',
  },
})
