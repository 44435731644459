import styled from 'styled-components'
import { COLOR } from '../common/styles'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

interface Props {
  status: number
  message: string
}

function ErrorHandling({ status, message }: Props) {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Typography
        variant="h4"
        color={COLOR.LIGHT_GRAY}
        textAlign="center"
        marginRight="20px"
      >
        {status}
      </Typography>
      <Typography>{t(message)}</Typography>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 95px;
  min-height: 100vh;
  width: calc(100vw - 95px);
`

export default ErrorHandling
