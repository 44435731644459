export default function CheckBoxOn() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.639404" width="24" height="24" rx="6" fill="#3779E4" />
      <path
        d="M10.1392 15.2464L7.22161 12.3288C6.9212 12.0284 6.43415 12.0284 6.13375 12.3288C5.83335 12.6292 5.83335 13.1163 6.13375 13.4167L9.59529 16.8782C9.89569 17.1786 10.3827 17.1786 10.6831 16.8782L19.1447 8.41667C19.4451 8.11627 19.4451 7.62922 19.1447 7.32882C18.8443 7.02841 18.3572 7.02841 18.0568 7.32882L10.1392 15.2464Z"
        fill="white"
      />
    </svg>
  )
}
