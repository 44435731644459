export default function UserProfileIcon({ width = '25', height = '24' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 8.25C10 6.86929 11.1193 5.75 12.5 5.75C13.8807 5.75 15 6.86929 15 8.25C15 9.63071 13.8807 10.75 12.5 10.75C11.1193 10.75 10 9.63071 10 8.25ZM9.37498 12L15.625 12C16.6605 12 17.5 12.8395 17.5 13.875C17.5 15.2701 16.9263 16.3878 15.9848 17.1435C15.0582 17.8872 13.8163 18.25 12.5 18.25C11.1837 18.25 9.94177 17.8872 9.01519 17.1435C8.07371 16.3878 7.5 15.2701 7.5 13.875C7.5 12.8395 8.33945 12 9.37498 12ZM12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2ZM3.75 12C3.75 7.16751 7.66751 3.25 12.5 3.25C17.3325 3.25 21.25 7.16751 21.25 12C21.25 16.8325 17.3325 20.75 12.5 20.75C7.66751 20.75 3.75 16.8325 3.75 12Z"
        fill="#202020"
      />
    </svg>
  )
}
