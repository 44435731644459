import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import React from 'react'

export default function CachingProvider({
  nonce = '',
  children,
}: {
  nonce: string | undefined
  children: React.ReactNode
}) {
  const cache = createCache({
    key: 'css',
    nonce,
    prepend: true,
  })

  return <CacheProvider value={cache}>{children}</CacheProvider>
}
