import { createContext, useContext, ReactNode } from 'react'
import { Env } from 'platform'

interface CommonContextProps {
  userEmail: string
  productAccess: Record<number, string>
  productNameIDMap: Record<string, number>
}

const defaultCommonContext: CommonContextProps = {
  userEmail: '',
  productAccess: {},
  productNameIDMap: {},
}

const CommonContext = createContext<CommonContextProps | undefined>(undefined)

interface CommonProviderProps extends CommonContextProps {
  children: ReactNode
}

export function CommonProvider({
  userEmail,
  productAccess,
  productNameIDMap,
  children,
}: CommonProviderProps) {
  return (
    <CommonContext.Provider
      value={{ userEmail, productAccess, productNameIDMap }}
    >
      {children}
    </CommonContext.Provider>
  )
}

export function useCommon(): CommonContextProps {
  const context = useContext(CommonContext)
  return context ?? defaultCommonContext
}
