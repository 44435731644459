export default function HomeIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.86783 3.35339C9.42658 2.8822 10.2436 2.8822 10.8023 3.35339L15.3035 7.14912C15.6416 7.43421 15.8366 7.85391 15.8366 8.29613V14.833C15.8366 15.4775 15.3142 16 14.6697 16H12.3357C11.6912 16 11.1688 15.4775 11.1688 14.833V11.1654C11.1688 11.0733 11.0941 10.9987 11.002 10.9987H8.6681C8.57602 10.9987 8.50139 11.0733 8.50139 11.1654V14.833C8.50139 15.4775 7.97891 16 7.33441 16H5.00047C4.35597 16 3.8335 15.4775 3.8335 14.833V8.29613C3.8335 7.85391 4.02858 7.43421 4.36665 7.14912L8.86783 3.35339ZM10.1575 4.11806C9.97124 3.961 9.69891 3.961 9.51266 4.11806L5.01148 7.91379C4.89879 8.00882 4.83376 8.14872 4.83376 8.29613V14.833C4.83376 14.9251 4.9084 14.9997 5.00047 14.9997H7.33441C7.42648 14.9997 7.50112 14.9251 7.50112 14.833V11.1654C7.50112 10.5209 8.02359 9.99843 8.6681 9.99843H11.002C11.6465 9.99843 12.169 10.5209 12.169 11.1654V14.833C12.169 14.9251 12.2437 14.9997 12.3357 14.9997H14.6697C14.7617 14.9997 14.8364 14.9251 14.8364 14.833V8.29613C14.8364 8.14872 14.7714 8.00882 14.6587 7.91379L10.1575 4.11806Z"
        fill="#202020"
      />
    </svg>
  )
}
