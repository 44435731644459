import { Env } from 'platform'

export interface ProductInfo {
  id: number
  name: string
}

export const QAT_PRODUCT_ID_TO_DISPLAYNAME_MAP: Record<number, string> = {
  1: 'IAM',
  2: 'Explorer',
  3: 'Client Portal',
  5: 'CMS',
  10: 'IDComply',
}

export const PREPROD_PRODUCT_ID_TO_DISPLAYNAME_MAP: Record<number, string> = {
  1: 'IAM',
  2: 'Explorer',
  5: 'CMS',
  8: 'Client Portal',
}

export const STG_PRODUCT_ID_TO_DISPLAYNAME_MAP: Record<number, string> = {
  1: 'IAM',
  2: 'Explorer',
  7: 'Client Portal',
  8: 'IDComply',
}

export const PROD_PRODUCT_ID_TO_DISPLAYNAME_MAP: Record<number, string> = {
  1: 'IAM',
  2: 'Explorer',
  5: 'CMS',
  8: 'Client Portal',
  9: 'IDComply',
}

export const getProductDisplayName = (productId: number, env: Env): string => {
  switch (env) {
    case Env.QAT:
      return QAT_PRODUCT_ID_TO_DISPLAYNAME_MAP[productId]
    case Env.PREPROD:
      return PREPROD_PRODUCT_ID_TO_DISPLAYNAME_MAP[productId]
    case Env.STG:
      return STG_PRODUCT_ID_TO_DISPLAYNAME_MAP[productId]
    case Env.PROD:
      return PROD_PRODUCT_ID_TO_DISPLAYNAME_MAP[productId]
    default:
      return QAT_PRODUCT_ID_TO_DISPLAYNAME_MAP[productId]
  }
}
