/**
 * @fileoverview gRPC-Web generated client stub for subscriptionservice
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: subscriptionservice.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as subscriptionservice_pb from './subscriptionservice_pb'; // proto import: "subscriptionservice.proto"


export class SubscriptionserviceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSubscribeNotification = new grpcWeb.MethodDescriptor(
    '/subscriptionservice.Subscriptionservice/SubscribeNotification',
    grpcWeb.MethodType.SERVER_STREAMING,
    subscriptionservice_pb.SubscribeNotificationRequest,
    subscriptionservice_pb.SubscribeNotificationResponse,
    (request: subscriptionservice_pb.SubscribeNotificationRequest) => {
      return request.serializeBinary();
    },
    subscriptionservice_pb.SubscribeNotificationResponse.deserializeBinary
  );

  subscribeNotification(
    request: subscriptionservice_pb.SubscribeNotificationRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<subscriptionservice_pb.SubscribeNotificationResponse> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/subscriptionservice.Subscriptionservice/SubscribeNotification',
      request,
      metadata || {},
      this.methodDescriptorSubscribeNotification);
  }

  methodDescriptorUnsubscribeNotification = new grpcWeb.MethodDescriptor(
    '/subscriptionservice.Subscriptionservice/UnsubscribeNotification',
    grpcWeb.MethodType.UNARY,
    subscriptionservice_pb.SubscribeNotificationRequest,
    subscriptionservice_pb.UnsubscribeNotificationResponse,
    (request: subscriptionservice_pb.SubscribeNotificationRequest) => {
      return request.serializeBinary();
    },
    subscriptionservice_pb.UnsubscribeNotificationResponse.deserializeBinary
  );

  unsubscribeNotification(
    request: subscriptionservice_pb.SubscribeNotificationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<subscriptionservice_pb.UnsubscribeNotificationResponse>;

  unsubscribeNotification(
    request: subscriptionservice_pb.SubscribeNotificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: subscriptionservice_pb.UnsubscribeNotificationResponse) => void): grpcWeb.ClientReadableStream<subscriptionservice_pb.UnsubscribeNotificationResponse>;

  unsubscribeNotification(
    request: subscriptionservice_pb.SubscribeNotificationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: subscriptionservice_pb.UnsubscribeNotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/subscriptionservice.Subscriptionservice/UnsubscribeNotification',
        request,
        metadata || {},
        this.methodDescriptorUnsubscribeNotification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/subscriptionservice.Subscriptionservice/UnsubscribeNotification',
    request,
    metadata || {},
    this.methodDescriptorUnsubscribeNotification);
  }

  methodDescriptorProlongSubscription = new grpcWeb.MethodDescriptor(
    '/subscriptionservice.Subscriptionservice/ProlongSubscription',
    grpcWeb.MethodType.UNARY,
    subscriptionservice_pb.SubscribeNotificationRequest,
    subscriptionservice_pb.UnsubscribeNotificationResponse,
    (request: subscriptionservice_pb.SubscribeNotificationRequest) => {
      return request.serializeBinary();
    },
    subscriptionservice_pb.UnsubscribeNotificationResponse.deserializeBinary
  );

  prolongSubscription(
    request: subscriptionservice_pb.SubscribeNotificationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<subscriptionservice_pb.UnsubscribeNotificationResponse>;

  prolongSubscription(
    request: subscriptionservice_pb.SubscribeNotificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: subscriptionservice_pb.UnsubscribeNotificationResponse) => void): grpcWeb.ClientReadableStream<subscriptionservice_pb.UnsubscribeNotificationResponse>;

  prolongSubscription(
    request: subscriptionservice_pb.SubscribeNotificationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: subscriptionservice_pb.UnsubscribeNotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/subscriptionservice.Subscriptionservice/ProlongSubscription',
        request,
        metadata || {},
        this.methodDescriptorProlongSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/subscriptionservice.Subscriptionservice/ProlongSubscription',
    request,
    metadata || {},
    this.methodDescriptorProlongSubscription);
  }

}

