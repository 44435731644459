import {
  FetchNotificationCountResponse,
  FetchNotificationCountRequest,
  FetchNotificationResponse,
  FetchNotificationRequest,
  PatchNotificationReadStateRequest,
  FetchLatestNotificationRequest,
  patchAllCenterNotificationsReadStateRequest,
} from '../../features/notifications/types/api-types'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  tagTypes: ['LiveUnreadCount', 'CenterNotifications'],
  endpoints: (builder) => ({
    fetchTableNotifications: builder.query<
      FetchNotificationResponse,
      FetchNotificationRequest
    >({
      query: (req) => ({
        url: '/v1/fetch-notification',
        method: 'POST',
        body: req,
      }),
      transformResponse: (response: FetchNotificationResponse) => {
        return response.notifications ? response : { notifications: [] }
      }, // Optimization: Backend should guard this!
      keepUnusedDataFor: 0,
    }),
    fetchLatestNotifications: builder.query<
      FetchNotificationResponse,
      FetchLatestNotificationRequest
    >({
      query: (req) => ({
        url: '/v1/fetch-notification',
        method: 'POST',
        body: req,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        if (queryArgs.cursor && Object.keys(queryArgs.cursor).length === 0) {
          return `${endpointName}-baseCollection`
        }
        return endpointName + JSON.stringify(queryArgs)
      },
      transformResponse: (response: FetchNotificationResponse) => {
        return response.notifications ? response : { notifications: [] }
      },
      keepUnusedDataFor: 0,
      providesTags: ['CenterNotifications'],
    }),
    fetchNotificationCount: builder.query<
      FetchNotificationCountResponse,
      FetchNotificationCountRequest
    >({
      query: (req) => ({
        url: '/v1/count-notification',
        method: 'POST',
        body: req,
      }),
      transformResponse: (response: FetchNotificationCountResponse) => {
        if (!response.count) {
          response.count = 0
        }
        if (!response.unreadCount) {
          response.unreadCount = 0
        }
        return response
      },
    }),
    fetchAllUnreadCount: builder.query<number, FetchNotificationCountRequest>({
      query: (req) => ({
        url: '/v1/count-notification',
        method: 'POST',
        body: req,
      }),
      transformResponse: (response: FetchNotificationCountResponse) => {
        return response.unreadCount ? response.unreadCount : 0
      },
      providesTags: ['LiveUnreadCount'],
    }),
    patchTableNotifications: builder.mutation<
      any,
      PatchNotificationReadStateRequest
    >({
      query: (req) => ({
        url: '/v1/notification',
        method: 'PATCH',
        body: req,
      }),
      invalidatesTags: ['LiveUnreadCount'],
    }),
    patchCenterNotification: builder.mutation<
      any,
      PatchNotificationReadStateRequest
    >({
      query: (req) => ({
        url: '/v1/notification',
        method: 'PATCH',
        body: req,
      }),
      invalidatesTags: ['LiveUnreadCount'],
      async onQueryStarted(
        { id, readState },
        { dispatch, queryFulfilled, getState },
      ) {
        const updatedIds = id
        const updatedId = updatedIds[0] // For our usecase there should only be 1 item in the array

        // Construct the dynamic cache key
        const cacheKey = `fetchLatestNotifications-baseCollection`

        // Correctly access the state for fetchLatestNotifications query
        const originalArgs = getState().api.queries[cacheKey]
          ?.originalArgs as FetchNotificationRequest
        // optimistic update
        const patchedResult = dispatch(
          apiSlice.util.updateQueryData(
            'fetchLatestNotifications',
            originalArgs,
            (data) => {
              const index = data.notifications.findIndex(
                (notification) => notification.id === updatedId,
              )
              if (index !== -1) {
                data.notifications.splice(index, 1)
              }
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchedResult.undo()
        }
      },
    }),
    // This API is meant to mark all unread notifications of the user as read
    // UseCase: mark all read inside the notification center
    patchAllCenterNotifications: builder.mutation<
      any,
      patchAllCenterNotificationsReadStateRequest
    >({
      query: (req) => ({
        url: '/v1/notification/all',
        method: 'PATCH',
        body: req,
      }),
      invalidatesTags: ['LiveUnreadCount', 'CenterNotifications'],
    }),
  }),
})

export const {
  useFetchTableNotificationsQuery,
  useFetchLatestNotificationsQuery,
  useLazyFetchLatestNotificationsQuery,
  useFetchAllUnreadCountQuery,
  useFetchNotificationCountQuery,
  usePatchTableNotificationsMutation,
  usePatchCenterNotificationMutation,
  usePatchAllCenterNotificationsMutation,
} = apiSlice
