import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Stack, IconButton, Typography } from '@mui/material'
import {
  GridRowId,
  GridColDef,
  GridRenderCellParams,
  GridRenderHeaderFilterProps,
  GridRowParams,
  GridActionsCellItem,
  GridFilterItem,
  GridFilterModel,
  GridSortModel,
  GridPaginationModel,
} from '@mui/x-data-grid-pro'
import CalendarHeaderFilter from '../components/CalendarHeaderFilter'
import { MessageWithLink, StyledChip, TypeTag } from '../components/common'
import { NotificationRadioWithToolTip } from '../components/NotificationRadioWithToolTip'
import SingleSelectHeaderFilter from '../components/SingleSelectHeaderFilter'
import { NotificationFilter } from '../types/common-types'
import { Notification } from '../types/api-types'
import dayjs from 'dayjs'
import { EXPLORER_FEATURES, isFeatureEnabled } from 'platform'
import { formatTimeWithUserTimezone, formatTimezone } from './time-helpers'
import { DisplayTimeFormat } from 'ui/src/components/forms/time/utils/TimeConstants'

export const createNotificationColumns = (
  rowIdExpanded: GridRowId | null,
  handleToggleRead: (id: string, nextReadState: boolean) => void,
  productOptions: Record<number, string>,
  typeOptions: string[],
): GridColDef[] => {
  return [
    {
      field: 'message',
      headerName: 'Message',
      minWidth: 504,
      flex: 1,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack flexDirection="row" height="100%">
            <IconButton
              sx={{
                boxSizing: 'content-box',
                width: '26px',
                height: '26px',
                padding: '12px',
              }}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{
                  fontSize: '16px',
                  transform: rowIdExpanded === params.id ? 'rotate(90deg)' : '',
                }}
              />
            </IconButton>
            <Stack
              padding="12px 16px 16px 16px"
              maxWidth="100%"
              overflow="hidden"
              maxHeight="100%"
            >
              <Typography
                variant={params.row.readState ? 'body1' : 'body1bold'}
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: rowIdExpanded === params.id ? 'normal' : 'nowrap',
                  lineHeight: '20px',
                  wordBreak: 'break-all',
                }}
              >
                {' '}
                {params.row.title}{' '}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: rowIdExpanded === params.id ? 'normal' : 'nowrap',
                  lineHeight: '20px',
                  wordBreak: 'break-all',
                }}
              >
                <MessageWithLink
                  message={params.row.message}
                  link={params.row.link}
                  linkName={params.row.linkName}
                />
              </Typography>
            </Stack>
          </Stack>
        )
      },
    },
    {
      field: 'deliveryDate',
      headerName: 'Delivery Date',
      minWidth: 220,
      flex: 0.44,
      renderCell: (params: GridRenderCellParams) => {
        const defaultUTCTime = dayjs
          .unix(params.value)
          .utc(params.value)
          .format(DisplayTimeFormat)
        const userTimeZone = localStorage.getItem('selectedTimezone') || 'UTC'
        const dateTime = isFeatureEnabled(EXPLORER_FEATURES.TIMEZONE_CONFIG)
          ? formatTimeWithUserTimezone(params.value, DisplayTimeFormat)
          : defaultUTCTime
        // Get current timezone of the user
        const timezone = formatTimezone(userTimeZone, 'hour')
        return (
          <Stack flexDirection="column">
            <Typography variant="body2"> {dateTime} </Typography>
            <Typography variant="body2"> {timezone} </Typography>
          </Stack>
        )
      },
      renderHeaderFilter: (params: GridRenderHeaderFilterProps) => (
        <CalendarHeaderFilter {...params} />
      ),
    },
    {
      field: 'product',
      headerName: 'Product',
      type: 'singleSelect',
      valueOptions: Object.values(productOptions),
      minWidth: 150,
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <StyledChip
            label={productOptions[params.value]}
            variant="outlined"
            sx={{ color: '#FF600F', borderColor: '#FF600F' }}
          />
        )
      },
      renderHeaderFilter: (params: GridRenderHeaderFilterProps) => (
        <SingleSelectHeaderFilter {...params} />
      ),
    },
    {
      field: 'sender',
      headerName: 'Sender',
      minWidth: 200,
      flex: 0.44,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack flexDirection="row" height="100%">
            <Typography
              variant="body2"
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: rowIdExpanded === params.id ? 'normal' : 'hidden',
                wordBreak: 'break-all',
              }}
            >
              {' '}
              {params.value}
            </Typography>
          </Stack>
        )
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: typeOptions,
      minWidth: 150,
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => {
        return <TypeTag label={params.value} />
      },
      renderHeaderFilter: (params: GridRenderHeaderFilterProps) => (
        <SingleSelectHeaderFilter
          {...params}
          translationPath="notification.actionType"
        />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 54,
      getActions: (params: GridRowParams) => {
        return [
          <GridActionsCellItem
            icon={
              <NotificationRadioWithToolTip readState={params.row.readState} />
            }
            onClick={() =>
              handleToggleRead(params.row.id, !params.row.readState)
            }
            label="Delete"
          />,
        ]
      },
    },
  ]
}

export const transformFilterModel = (
  model: GridFilterModel,
  productNameIDMap: Record<string, number>,
) => {
  const headerFilterState: Partial<NotificationFilter> = {
    message: '',
    product: 0,
    sender: '',
    type: '',
    startFrom: 0,
    endAt: 0,
  }
  model.items.forEach((item: GridFilterItem) => {
    switch (item.field) {
      case 'message':
        headerFilterState.message = item.value || ''
        break
      case 'product':
        headerFilterState.product = productNameIDMap[item.value] || 0
        break
      case 'sender':
        headerFilterState.sender = item.value || ''
        break
      case 'type':
        headerFilterState.type = item.value || ''
        break
      case 'deliveryDate':
        headerFilterState.startFrom = item.value[0] || 0
        headerFilterState.endAt = item.value[1] || 0
        break
      default:
        break
    }
  })

  return headerFilterState
}

export const transformSortModel = (model: GridSortModel) => {
  const sortState: Partial<NotificationFilter> = {}
  switch (model[0]?.field) {
    case 'deliveryDate':
      sortState.sortBy = 'CREATED_AT'
      break
    case 'product':
      sortState.sortBy = 'PRODUCT'
      break
    case 'type':
      sortState.sortBy = 'TYPE'
      break
    default:
      break
  }
  if (model[0]?.sort) {
    sortState.order = model[0].sort === 'asc' ? 'ASC' : 'DESC'
  }
  return sortState
}

// The changed attribute will be first item in the array
export const transformPaginationModel = (model: GridPaginationModel) => {
  const paginationState: Partial<NotificationFilter> = {}
  const Keys = Object.keys(model)
  if (Keys[0] === 'pageSize') {
    paginationState.limit = model.pageSize
    paginationState.page = 0 // Propogate table back to first page
  } else {
    paginationState.page = model.page
  }
  return paginationState
}

export const transformNotificationDataToRows = (
  notifications: Notification[],
) => {
  return notifications.map((notification) => {
    return {
      id: notification.id,
      title: notification.title,
      message: notification.message,
      deliveryDate: notification.createdAt,
      product: notification.product,
      sender: notification.sender,
      type: notification.type,
      readState: notification.readState || false,
      link: notification.link,
      linkName: notification.linkName,
    }
  })
}
