import { AppBar, Box, Button, Stack, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { UserPortal } from 'platform'
import React, { useCallback, useMemo, useState } from 'react'
import {
  AppDrawerIcon,
  GeocomplyLogo,
  HomeIcon,
  QuestionIcon,
  UserProfileIcon,
} from 'ui'
import ActionItem, {
  ActionItemWithLink,
  ActionItemWithPopper,
} from './ActionItem'
import { LngAndTimezoneIcon } from './LngAndTimezoneIcon'
import { NotificationMenu } from './Notifications'
import NotificationBadge from './NotificationBadge'
import PreferenceModal from './PreferenceModal'
import { ProductsDrawer } from './ProductsDrawer'
import { ProfileMenu } from './ProfileMenu'

export type TopBarProps = {
  homeUrl: string
  userName: string
  logOutURL: string
  portalId: number | string
  portals: UserPortal[]
  enableTimezone?: boolean
  enableMultiLng?: boolean
  newFont?: boolean
  notificationCenterBadge?: JSX.Element
  notificationCenter?: JSX.Element
}

export default function TopBar({
  homeUrl,
  userName,
  logOutURL,
  portalId,
  portals,
  enableTimezone = false,
  enableMultiLng = false,
  newFont = false,
  notificationCenterBadge,
  notificationCenter,
}: TopBarProps) {
  const { origin, pathname } = window.location

  const isLandingPage = useMemo(() => {
    return origin === homeUrl && pathname === '/'
  }, [origin, pathname])

  const navigateToHome = useCallback(() => {
    if (homeUrl) {
      window.open(homeUrl, '_blank')
    }
  }, [])

  return (
    <TopBarWrapper
      position="fixed"
      sx={{
        ' .MuiTypography-root': {
          fontFamily: newFont ? 'Inter' : 'Work Sans, sans-serif',
        },
      }}
    >
      <Toolbar>
        {/* Logo and Home */}
        <Stack direction="row" height="44px" width="257px">
          <Logo onClick={navigateToHome}>
            <GeocomplyLogo width={133.83} height={22} />
          </Logo>
          <Home
            startIcon={<HomeIcon />}
            onClick={navigateToHome}
            className={isLandingPage ? 'Mui-active' : ''}
          >
            <Typography variant="body2">Home</Typography>
          </Home>
        </Stack>
        {/* Action items */}
        <ActionItemsBar>
          {generateActionItems({
            portals,
            portalId,
            userName,
            logOutURL,
            notificationCenterBadge,
            notificationCenter,
            enableMultiLng,
            enableTimezone,
          })}
        </ActionItemsBar>
      </Toolbar>
    </TopBarWrapper>
  )
}

enum ActionItems {
  LANGUAGE_AND_TIMEZONE = 'Language And Region',
  NOTIFICATIONS = 'Notifications',
  HELP = 'Help',
  PRODUCTS = 'Products',
  PROFILE = 'Profile',
}

export type ActionItemsConfigProps = {
  [key in ActionItems]: {
    icon: (...paras: any) => JSX.Element
    type: 'menu' | 'link' | 'modal' | 'custom'
    customTitle?: string
    disable?: boolean
    children?: React.ReactNode
    url?: string
  }
}

const generateActionItems = ({
  portals,
  portalId,
  userName,
  logOutURL,
  notificationCenterBadge,
  notificationCenter,
  enableMultiLng = false,
  enableTimezone = false,
}: Omit<TopBarProps, 'homeUrl'>) => {
  const { openPreferenceModal, closePreferenceModal, isPreferenceModalOpen } =
    useModalManagement()
  const { isDisabled, customTitle } = checkLanguageAndTimeZoneEnabled(
    enableMultiLng,
    enableTimezone,
  )

  // Generate Action Items Config
  const ActionItemsConfig: ActionItemsConfigProps = {
    [ActionItems.LANGUAGE_AND_TIMEZONE]: {
      icon: () => <LngAndTimezoneIcon enableTimezone={enableTimezone} />,
      type: 'modal',
      disable: isDisabled,
      customTitle,
      children: (
        <PreferenceModal
          enableMultiLng={enableMultiLng}
          enableTimezone={enableTimezone}
          isOpen={isPreferenceModalOpen}
          onClose={closePreferenceModal}
        />
      ),
    },
    [ActionItems.NOTIFICATIONS]: {
      icon: () => notificationCenterBadge || <NotificationBadge />,
      type: 'menu',
      children: notificationCenter || <NotificationMenu />,
    },
    [ActionItems.HELP]: {
      icon: () => <QuestionIcon width="20" height="20" />,
      type: 'link',
      url: 'https://geocomply.my.site.com/Portal/s/',
    },
    [ActionItems.PRODUCTS]: {
      icon: () => <AppDrawerIcon width="20" height="20" />,
      type: 'menu',
      children: <ProductsDrawer portals={portals} currentPortalId={portalId} />,
    },
    [ActionItems.PROFILE]: {
      icon: () => <UserProfileIcon width="20" height="20" />,
      type: 'custom',
      children: (
        <ProfileMenu
          userName={userName}
          logOutURL={logOutURL}
          enableMultiLng={enableMultiLng}
          enableTimezone={enableTimezone}
        />
      ),
    },
  }

  // Render Action Items
  return Object.values(ActionItems).map((actionItemName) => {
    const { icon, type, children, url, customTitle, disable } =
      ActionItemsConfig[actionItemName]
    if (disable) return null
    if (type === 'modal') {
      return (
        <ActionItem
          key={actionItemName}
          title={customTitle || actionItemName}
          icon={icon}
          onClick={openPreferenceModal}
          style={{
            width: 'auto',
          }}
        >
          {children}
        </ActionItem>
      )
    }
    if (type === 'link') {
      return (
        <ActionItemWithLink
          key={actionItemName}
          title={actionItemName}
          icon={icon}
          url={url as string}
        />
      )
    }
    if (type === 'custom') {
      return <React.Fragment key="Profile">{children}</React.Fragment>
    }
    return (
      <ActionItemWithPopper
        key={actionItemName}
        title={actionItemName}
        icon={icon}
      >
        {children}
      </ActionItemWithPopper>
    )
  })
}

function useModalManagement() {
  const [isPreferenceModalOpen, setPreferenceModalOpen] = useState(false)
  return {
    isPreferenceModalOpen,
    openPreferenceModal: () => setPreferenceModalOpen(true),
    closePreferenceModal: () => setPreferenceModalOpen(false),
  }
}

const checkLanguageAndTimeZoneEnabled = (
  enableMultiLng: boolean,
  enableTimezone: boolean,
) => {
  const isDisabled = !enableMultiLng && !enableTimezone
  const customTitle: string =
    enableMultiLng && enableTimezone
      ? 'Language and region'
      : enableMultiLng
        ? 'Language'
        : 'Time zone'
  return {
    isDisabled,
    customTitle,
  }
}

const TopBarWrapper = styled(AppBar)(({ theme }) => ({
  'z-index': '1000',
  'backgroundColor': '#fff',
  'borderBottom': '1px solid rgba(0, 0, 0, 0.12)',
  '&.MuiAppBar-root.MuiPaper-root': {
    boxShadow: 'none',
    transition: 'none',
  },
  '& .MuiToolbar-root': {
    justifyContent: 'space-between',
    padding: '14px 27px',
    height: '75px',
  },
  '& .MuiButton-startIcon': {
    margin: '0',
  },
  '& .MuiIconButton-root': {
    width: '51px',
    height: '48px',
    padding: '12px 13.5px',
    backgroundColor: '#fff',
  },
  '& .MuiIconButton-root,.MuiButton-root': {
    '&:hover': {
      backgroundColor: '#EFF7FF',
    },
    '&.Mui-active': {
      'backgroundColor': theme.palette.primary.light,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
}))

const Logo = styled(Box)(() => ({
  cursor: 'pointer',
  padding: '11px 30px 11px 0',
  width: '133.83px',
  height: '22px',
}))

const Home = styled(Button)(() => ({
  display: 'flex',
  gap: '8px',
  width: '93px',
  height: '42px',
  textTransform: 'none',
}))

const ActionItemsBar = styled(Stack)(() => ({
  flexDirection: 'row',
  height: '48px',
  minWidth: '228px',
  gap: '8px',
}))
