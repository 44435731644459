import React from 'react'
import { COLOR } from '../common/styles'

function LogOutIcon({ color = COLOR.TEXT_LIGHT, width = '14', height = '16' }) {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 14 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.05 0.650024C1.05589 0.650024 0.25 1.45591 0.25 2.45002V13.25C0.25 14.2441 1.05589 15.05 2.05 15.05H5.88145C5.65318 14.7736 5.45435 14.472 5.28974 14.15H2.05C1.55294 14.15 1.15 13.7471 1.15 13.25V2.45002C1.15 1.95297 1.55294 1.55002 2.05 1.55002H9.25C9.74706 1.55002 10.15 1.95297 10.15 2.45002V6.9702C10.4591 6.99806 10.7599 7.05432 11.05 7.13635V2.45002C11.05 1.45591 10.2441 0.650024 9.25 0.650024H2.05ZM3.85 7.85002C3.85 8.34708 3.44706 8.75002 2.95 8.75002C2.45294 8.75002 2.05 8.34708 2.05 7.85002C2.05 7.35297 2.45294 6.95002 2.95 6.95002C3.44706 6.95002 3.85 7.35297 3.85 7.85002ZM13.75 11.9C13.75 14.1368 11.9368 15.95 9.7 15.95C7.46325 15.95 5.65 14.1368 5.65 11.9C5.65 9.66327 7.46325 7.85002 9.7 7.85002C11.9368 7.85002 13.75 9.66327 13.75 11.9ZM7.58216 11.5815L7.5799 11.5837C7.5377 11.6264 7.50578 11.6755 7.48415 11.7278C7.46247 11.78 7.45036 11.8373 7.45001 11.8973L7.45 11.9L7.45001 11.9027C7.45036 11.9628 7.46247 12.02 7.48415 12.0723C7.5061 12.1254 7.53866 12.1751 7.5818 12.2182L9.3818 14.0182C9.55754 14.194 9.84246 14.194 10.0182 14.0182C10.1939 13.8425 10.1939 13.5576 10.0182 13.3818L8.98639 12.35H11.5C11.7485 12.35 11.95 12.1486 11.95 11.9C11.95 11.6515 11.7485 11.45 11.5 11.45H8.9864L10.0182 10.4182C10.1939 10.2425 10.1939 9.95756 10.0182 9.78183C9.84246 9.60609 9.55754 9.60609 9.3818 9.78183L7.58216 11.5815Z"
        fill={color}
      />
    </svg>
  )
}

export default LogOutIcon
