interface Props {
  color: string
  width?: string | number
  height?: string | number
}

function Checked({ color, width, height }: Props) {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 24 25"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0.5C18.6274 0.5 24 5.87258 24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5C0 5.87258 5.37258 0.5 12 0.5ZM12 2C6.20101 2 1.5 6.70101 1.5 12.5C1.5 18.299 6.20101 23 12 23C17.799 23 22.5 18.299 22.5 12.5C22.5 6.70101 17.799 2 12 2ZM17.1803 8.66967C17.4732 8.96256 17.4732 9.43744 17.1803 9.73033L10.5803 16.3303C10.2874 16.6232 9.81256 16.6232 9.51967 16.3303L6.81967 13.6303C6.52678 13.3374 6.52678 12.8626 6.81967 12.5697C7.11256 12.2768 7.58744 12.2768 7.88033 12.5697L10.05 14.7393L16.1197 8.66967C16.4126 8.37678 16.8874 8.37678 17.1803 8.66967Z"
        fill={color}
      />
    </svg>
  )
}

export default Checked
