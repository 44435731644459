import { Typography } from '@mui/material'
import { useMemo } from 'react'
import { NavigationIcon } from 'ui'
import { useTranslation } from 'react-i18next'
import { COLOR } from '../../common/styles'

const getFormattedTimezoneOffset = () => {
  const timeZone = localStorage.getItem('selectedTimezone') || 'UTC'

  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeZoneName: 'shortOffset',
  })

  let offset = formatter
    .formatToParts()
    .find((part) => part.type === 'timeZoneName')!
    .value.substring(3)

  if (!offset) {
    offset += '+0'
  }

  return offset
}

interface Props {
  enableTimezone: boolean
}

export function LngAndTimezoneIcon({ enableTimezone }: Props) {
  const { t } = useTranslation()
  const formattedOffset = useMemo(getFormattedTimezoneOffset, [])
  return (
    <>
      {enableTimezone && (
        <Typography
          variant="body2"
          sx={{ marginRight: 2, color: COLOR.MAIN_BLACK_50 }}
        >
          {`${t('UTC')} ${formattedOffset}`}
        </Typography>
      )}
      <NavigationIcon />
    </>
  )
}
