import { createLeaderElection, BroadcastChannel } from 'broadcast-channel'
import { useEffect, useState } from 'react'
import NotificationBadge from 'ui/src/widgets/topBar/NotificationBadge'
import { useCommon } from '../../../context/commonContext'
import { useAppDispatch } from '../../../redux/hooks/redux-hooks'
import {
  apiSlice,
  useFetchAllUnreadCountQuery,
} from '../../../redux/slices/api-slice'
import { AppDispatch } from '../../../redux/store'
import { STREAM_IGNORED_MESSAGE } from '../common/constants'
import { SubscriptionserviceClient } from '../grpcClient/proto/SubscriptionserviceServiceClientPb'
import { SubscribeNotificationRequest } from '../grpcClient/proto/subscriptionservice_pb'

const subscribeNotificationServerStream = (
  dispatch: AppDispatch,
  channel: BroadcastChannel,
) => {
  // Todo: Need Correct URL here
  const subscriptionService = new SubscriptionserviceClient(
    'https://qat-explorer.dev.g7y.io/',
  )
  const subscribeNotificationRequest = new SubscribeNotificationRequest()
  const serverStream = subscriptionService.subscribeNotification(
    subscribeNotificationRequest,
  )
  serverStream.on('data', function (response) {
    const message = response.getNotification()
    if (
      message !== STREAM_IGNORED_MESSAGE.streamOpened &&
      message !== STREAM_IGNORED_MESSAGE.keepAlive
    ) {
      dispatch(apiSlice.util.invalidateTags(['LiveUnreadCount']))
      channel.postMessage('Received Notification')
    }
  })
  const minuteInMs = 1 * 60000
  const prolongIntervalTime = 59 * minuteInMs
  const prolongInterval = setInterval(() => {
    subscriptionService.prolongSubscription(subscribeNotificationRequest)
  }, prolongIntervalTime)

  return { prolongInterval, serverStream }
}

export default function NotificationCenterBadge() {
  const dispatch = useAppDispatch()
  const { userEmail } = useCommon()
  const [prolongInterval, setProlongInterval] = useState<NodeJS.Timeout | null>(
    null,
  )
  const [serverStream, setServerStream] = useState<any>(null)

  useEffect(() => {
    const channel = new BroadcastChannel('notificationServerStream')
    const elector = createLeaderElection(channel)
    channel.onmessage = (message) => {
      if (message === 'Received Notification') {
        dispatch(apiSlice.util.invalidateTags(['LiveUnreadCount']))
      }
    }

    elector.awaitLeadership().then(() => {
      const { prolongInterval, serverStream } =
        subscribeNotificationServerStream(dispatch, channel)
      setProlongInterval(prolongInterval)
      setServerStream(serverStream)
    })

    return () => {
      if (prolongInterval) {
        clearInterval(prolongInterval)
      }
      if (serverStream) {
        serverStream.cancel()
      }
    }
  }, [dispatch])

  const { data: unreadCount, isSuccess } = useFetchAllUnreadCountQuery({
    receiver: userEmail,
  })

  const count = isSuccess && unreadCount ? unreadCount : 0
  return <NotificationBadge unreadCount={count} />
}
