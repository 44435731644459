import {
  Typography,
  MenuList,
  MenuItem,
  styled,
  ClickAwayListener,
  Popper,
} from '@mui/material'
import { useState } from 'react'
import { LogOutIcon, UserProfileIcon } from 'ui'
import PreferenceModal from './PreferenceModal'
import { useTranslation } from 'react-i18next'
import ActionItem from './ActionItem'

export type ProfileMenuProps = {
  userName: string
  logOutURL: string
  enableMultiLng: boolean
  enableTimezone: boolean
}

export function ProfileMenu({
  userName,
  logOutURL,
  enableMultiLng,
  enableTimezone,
}: ProfileMenuProps) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const showPreference = enableMultiLng || enableTimezone
  const [isPreferenceModalOpen, setIsPreferenceModalOpen] = useState(false)
  const isActive = Boolean(anchorEl)

  return (
    <ActionItem
      title={t('Profile')}
      icon={() => <UserProfileIcon width="20" height="20" />}
      isActive={isActive}
      onClick={handleClick}
    >
      <StyledPopper
        open={isActive}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={{ zIndex: 1200 }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-3, 11.5],
            },
          },
        ]}
      >
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null)
          }}
        >
          <MenuList>
            <StyledMenuItem disabled sx={{ opacity: 1 }}>
              <Typography variant="body2" fontWeight={700}>
                {userName}
              </Typography>
            </StyledMenuItem>
            {showPreference && (
              <StyledMenuItem
                onClick={() => {
                  setIsPreferenceModalOpen(true)
                  setAnchorEl(null)
                }}
              >
                <Typography
                  data-testid="timezone-settings-button"
                  variant="body2"
                >
                  {t('Preferences')}
                </Typography>
              </StyledMenuItem>
            )}
            <StyledMenuItem
              onClick={() => {
                sessionStorage.clear()
                window.location.replace(logOutURL)
              }}
            >
              <LogOutIcon />
              <Typography variant="body2">{t('Log Out')}</Typography>
            </StyledMenuItem>
          </MenuList>
        </ClickAwayListener>
      </StyledPopper>
      <PreferenceModal
        enableMultiLng={enableMultiLng}
        enableTimezone={enableTimezone}
        isOpen={isPreferenceModalOpen}
        onClose={() => setIsPreferenceModalOpen(false)}
      />
    </ActionItem>
  )
}

const StyledMenuItem = styled(MenuItem)`
  opacity: 1 !important;
  height: 36px;
  gap: 10px;
  padding: 8px 10px;
  &:hover {
    background-color: #1e90ff;
    & .MuiTypography-root {
      color: #fff;
    }
    & svg path {
      fill: #fff;
    }
  }
`

const StyledPopper = styled(Popper)(() => ({
  'zIndex': 1200,
  'padding': '5px',
  'gap': '3px',
  'width': '255px',
  'borderRadius': '8px',
  'boxShadow': 'rgba(21, 21, 18, 0.25) 0px 35px 120px 0',
  'backgroundColor': '#fff',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-10px',
    right: '10px',
    width: '0',
    height: '0',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid #fff',
  },
}))
