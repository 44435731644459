import {
  styled,
  Stack,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight } from '@fortawesome/pro-regular-svg-icons'

import Divider from '@mui/material/Divider'
import NotificationList from './NotificationList'
import NotificationBanner from './NotificationBanner'
import { NotificationCenterRow } from '../types/common-types'
import { DelightIcon, LoadingSpinner, MarkAllReadButton } from './common'
import { useTranslation } from 'react-i18next'
import { usePatchAllCenterNotificationsMutation } from '../../../redux/slices/api-slice'
import { useInfiniteCenterNotificationsQuery } from '../../../redux/hooks/notification-hooks'
import { useCommon } from '../../../context/commonContext'

export default function NotificationCenter() {
  const { t } = useTranslation()
  const { userEmail } = useCommon()
  const {
    data,
    isSuccess,
    isLoading,
    isFetching,
    isFetchingNext,
    hasNext,
    fetchNext,
    refetch,
  } = useInfiniteCenterNotificationsQuery(userEmail)
  const [markAllRead, { isLoading: isUpdating }] =
    usePatchAllCenterNotificationsMutation()

  const fetchedNotifications: NotificationCenterRow[] =
    (data?.notifications as NotificationCenterRow[]) || []

  const hasUnreadNotification = fetchedNotifications.length > 0
  const LoadingSpinnerHeight = hasUnreadNotification ? '692px' : '256px'

  // Initial Load opens 5
  const loadNotifications = () => {
    if (hasNext && !isFetchingNext) {
      setTimeout(() => fetchNext(), 200)
    }
  }

  return (
    <NotificationContainer>
      <NotificationHeader unreadStyle={hasUnreadNotification}>
        <Typography variant="h4">{t('notification.notifications')} </Typography>
        <MarkAllReadButton
          color="#3779E4"
          onClick={() => markAllRead({ receiver: userEmail, readState: true })}
        />
      </NotificationHeader>
      <Divider sx={{ margin: '16px 0px' }} />

      {(isLoading || isFetching) && (
        <LoadingSpinner height={LoadingSpinnerHeight} />
      )}
      {isSuccess && !isLoading && !isFetching && (
        <NotificationsComponent
          fetchedNotifications={fetchedNotifications}
          loadNotifications={loadNotifications}
          loadingMore={hasNext}
        />
      )}

      <NotificationFooter>
        <Button
          variant="outlined"
          sx={{ marginBottom: '8px' }}
          onClick={() =>
            window.open('https://qat-explorer.dev.g7y.io/all-notifications')
          } // Todo: Redirection to IAM URL
        >
          {t('notification.viewAllNotifications')}
        </Button>
        <Button
          sx={{ color: '#3779E4' }}
          startIcon={<FontAwesomeIcon icon={faRotateRight} />}
          onClick={() => refetch()}
        >
          {t('notification.refreshNotifications')}
        </Button>
      </NotificationFooter>
    </NotificationContainer>
  )
}

interface NotificationComponentProps {
  fetchedNotifications: NotificationCenterRow[]
  loadNotifications: () => void
  loadingMore: boolean
}

function NotificationsComponent({
  fetchedNotifications,
  loadNotifications,
  loadingMore,
}: NotificationComponentProps) {
  const { t } = useTranslation()

  if (fetchedNotifications.length === 0) {
    return (
      <Stack height="256px" sx={{ overflowX: 'hidden' }}>
        <NotificationBanner
          icon={<DelightIcon />}
          height="256px"
          description={t('notification.allCaughtUp')}
        />
      </Stack>
    )
  }

  return (
    <Stack height="692px" sx={{ overflowX: 'hidden' }}>
      <NotificationList
        data={fetchedNotifications}
        loadingMore={loadingMore}
        loadNotifications={loadNotifications}
      />
    </Stack>
  )
}

const NotificationContainer = styled(Stack)(() => ({
  backgroundColor: '#ffffff',
  borderColor: 'rgba(20, 20, 20, 0.1)',
  borderWeight: '1px',
  borderRadius: '10px',
  dropShadow: '1px 4px 60px 0px #000000',
  width: '600px',
  maxHeight: '780px',
}))

const NotificationHeader = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'unreadStyle',
})<{ unreadStyle: boolean }>(({ unreadStyle }) => ({
  width: '600px',
  minWidth: '600px',
  minHeight: unreadStyle ? '58px' : '44px',
  Height: unreadStyle ? '58px' : '44px',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  paddingTop: '16px',
  paddingLeft: '24px',
  paddingRight: '24px',
}))

const NotificationFooter = styled(Stack)(() => ({
  padding: '16px',
  height: '132px',
}))
