import { Button, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MessageWithLink } from './common'

interface Props {
  isMessageEclipsed: boolean
  isLinkEclipsed: boolean
  message: string
  link?: string
  linkName?: string
}

export default function NotificationCenterMsg({
  isMessageEclipsed,
  isLinkEclipsed,
  message,
  link,
  linkName,
}: Props) {
  const { t } = useTranslation()
  const messageTypographyStyle = {
    'textOverflow': 'ellipsis',
    'overflow': 'hidden',
    'whiteSpace': 'nowrap',
    'lineHeight': '20px',
    'wordBreak': 'break-all',
    'width': '361px',
    '& a': { color: 'primary.main' },
  }

  const messageContent = (
    <Typography variant="body2" lineHeight="20px" sx={messageTypographyStyle}>
      <MessageWithLink message={message} link={link} linkName={linkName} />
    </Typography>
  )

  if (!isMessageEclipsed && !isLinkEclipsed) {
    return messageContent
  }
  // Todo: Replace to IAM URL
  return (
    <Stack marginTop="4px">
      <Stack flexDirection="row" alignItems="center">
        {messageContent}
        {isMessageEclipsed && (
          <Link
            href="https://qat-explorer.dev.g7y.io/all-notifications"
            rel="noopener noreferrer"
            target="_blank"
            sx={{
              textDecoration: 'none',
              marginLeft: '8px',
            }}
          >
            <Typography variant="button" color="primary">
              {t('read more')}
            </Typography>
          </Link>
        )}
      </Stack>
      {isLinkEclipsed && (
        <Link href={link} rel="noopener noreferrer" target="_blank">
          <Typography variant="body2" color="primary">
            {linkName || link}
          </Typography>
        </Link>
      )}
    </Stack>
  )
}
