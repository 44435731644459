function isFeatureEnabled(feature: string): boolean {
  const features = (window as any).UBO_USER_EXPLORER_FEATURES
  return features?.[feature] ?? false
}

// -----------------------------------------------------------------
// Explorer feature flags
enum EXPLORER_FEATURES {
  EXPORT = 'export',
  DASHBOARD = 'dashboard',
  REPORTS = 'reports',
  MAP = 'map',
  EXPLORER = 'explorer',
  LISTING_NOTE = 'listing-note',
  LOG_ROCKET = 'log-rocket',
  LISTING_CACHE = 'listing-cache',
  LISTING_FILTER = 'listing-filter',
  MULTI_SEARCH = 'multi-search',
  MULTI_LEVEL_V2 = 'multi-level-v2',
  MULTI_SEARCH_GRAPH = 'multi-search-graph',
  POWER_MAP = 'power-map',
  POWER_MAP_EXPORT = 'power-map-export',
  TIMEZONE_CONFIG = 'timezone-config',
  CHAMELEON = 'chameleon',
  WEBVAITALS = 'webvitals',
  STADIUM_INSIGHTS = 'stadium-insights',
  BLOCK_V2 = 'mass-block',
  WATCHTOWER = 'watchtower',
  UNBLOCK_V2 = 'mass-unblock',
  CUSTOM_FIELD = 'custom-field',
  MULTI_LANGUAGE = 'multi-language',
  BM_V2 = 'block-management-v2',
  NOTIFICATION = 'notification',
  EDGE_STAGE = 'edge-stage',
  ANTIFRAUD_TAB_PERM = 'antifraud-tab-perm',
}

export { isFeatureEnabled, EXPLORER_FEATURES }
