import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  useThemeProps,
} from '@mui/material'
import {
  GridRenderHeaderFilterProps,
  GridSingleSelectColDef,
  useGridApiContext,
  useGridSelector,
  gridFilterModelSelector,
} from '@mui/x-data-grid-pro'
import { useMemo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

// Custom Single Select Filter to be refactored later
const getDefaultFilter = (field: string) => ({ field, operator: 'is' })

interface SingleSelectHeaderFilterProps extends GridRenderHeaderFilterProps {
  translationPath?: string
}

export default function SingleSelectHeaderFilter({
  translationPath,
  ...props
}: SingleSelectHeaderFilterProps) {
  const { t } = useTranslation()
  const { colDef } = props as { colDef: GridSingleSelectColDef }
  const valueOptions = colDef.valueOptions
    ? (colDef.valueOptions as string[])
    : ([] as string[])
  const apiRef = useGridApiContext()
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector)
  const currentFieldFilters = useMemo(
    () => filterModel?.items?.filter(({ field }) => field === colDef.field),
    [colDef.field, filterModel.items],
  )

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      if (!event.target.value) {
        if (currentFieldFilters[0]) {
          apiRef.current.deleteFilterItem(currentFieldFilters[0])
        }
        return
      }
      apiRef.current.upsertFilterItem({
        ...(currentFieldFilters[0] || getDefaultFilter(colDef.field)),
        id: `${colDef.field}`,
        value: event.target.value,
      })
    },
    [apiRef, colDef.field, currentFieldFilters],
  )
  const value = currentFieldFilters[0]?.value ?? ''
  const label = 'Is'
  const id = `select-is-${colDef.field}`
  const idLabel = `${id}-label`

  const [isOpen, setIsOpen] = useState(false)

  return (
    <FormControl
      variant="standard"
      sx={{
        'm': 1,
        'minWidth': 120,
        '& label, .MuiInputBase-input': {
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '22px',
          letterSpacing: '0.25px',
        },
        [`#${id}:focus`]: {
          backgroundColor: 'transparent',
        },
      }}
      fullWidth
    >
      <InputLabel id={idLabel}>{t(`${label}`)}</InputLabel>
      <Select
        labelId={idLabel}
        id={id}
        value={value}
        onChange={handleChange}
        label={label}
        MenuProps={{
          PaperProps: {
            sx: {
              borderRadius: '8px',
            },
          },
        }}
        IconComponent={() => (
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{
              fontSize: '16px',
              pointerEvents: 'none',
              position: 'absolute',
              right: '10px',
              transform: isOpen ? 'rotate(90deg)' : '',
            }}
          />
        )}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        <MenuItem sx={{ borderBottom: '1px solid #E0E0E0' }} value="">
          <Typography key="default" variant="body2">
            {t('table.selectAll')}
          </Typography>
        </MenuItem>
        {valueOptions.map((option, i) => (
          <MenuItem key={i} value={option}>
            {' '}
            <Typography variant="body2">
              {' '}
              {translationPath
                ? t(`${translationPath}.${option}`)
                : option}{' '}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
