/* eslint-disable @typescript-eslint/no-unused-expressions */
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NOTIFICATION_LOAD_LIMIT } from '../../features/notifications/common/constants'
import {
  constructFetchNotificationRequest,
  constructFetchNotificationCountRequest,
} from '../../features/notifications/common/notification-helpers'
import {
  FetchLatestNotificationRequest,
  FetchNotificationCountRequest,
  FetchNotificationRequest,
} from '../../features/notifications/types/api-types'
import { NotificationFilter } from '../../features/notifications/types/common-types'
import {
  apiSlice,
  useFetchLatestNotificationsQuery,
  useFetchNotificationCountQuery,
  useFetchTableNotificationsQuery,
  useLazyFetchLatestNotificationsQuery,
} from '../slices/api-slice'
import { updateNotificationFilter } from '../slices/notification-slice'
import {
  useAppDispatch,
  useAppSelector,
  useNotificationFilterSelector,
} from './redux-hooks'

export const useSetNotificationFilter = () => {
  const dispatch = useDispatch()
  // This hook is used to update the notification filter, and remove tableNotifications Cache
  const setNotificationFilter = useCallback(
    (updatedFilter: Partial<NotificationFilter>) => {
      dispatch(
        updateNotificationFilter({
          ...updatedFilter,
          tableNotifications: {},
          tableCursor: {},
        }),
      )
    },
    [dispatch],
  )

  const setFilterPagination = useCallback(
    (updatedFilter: Partial<NotificationFilter>) => {
      dispatch(updateNotificationFilter(updatedFilter))
    },
    [dispatch],
  )

  return {
    setNotificationFilter,
    setFilterPagination,
  }
}

export const useMyFetchTableNotificationsQuery = (
  receiver: string,
  currentEndAt: number,
  page: number,
  skip?: boolean,
) => {
  const notificationFilter = useNotificationFilterSelector()
  const { tableCursor } = useAppSelector((state) => state.notification)
  const fetchRequest: FetchNotificationRequest =
    constructFetchNotificationRequest(
      notificationFilter,
      receiver,
      page,
      currentEndAt,
      tableCursor,
    )

  if (skip) {
    return useFetchTableNotificationsQuery(fetchRequest, { skip: true })
  }

  return useFetchTableNotificationsQuery(fetchRequest)
}

export const useMyFetchNotificationsCountQuery = (
  receiver: string,
  currentEndAt: number,
) => {
  const notificationFilter = useNotificationFilterSelector()

  const fetchCountRequest: FetchNotificationCountRequest =
    constructFetchNotificationCountRequest(
      notificationFilter,
      currentEndAt,
      receiver,
    )

  return useFetchNotificationCountQuery(fetchCountRequest)
}

type QueryError = FetchBaseQueryError | SerializedError | undefined

export interface UseInfiniteQueryResult {
  data: Notification[]

  // for the very first fetching
  error?: QueryError
  isError: boolean
  isLoading: boolean

  // for the first fetching and next fetching
  isFetching: boolean

  errorNext: QueryError
  isErrorNext: boolean
  isFetchingNext: boolean
  hasNext: boolean
  fetchNext: () => Promise<void>
  refetch: () => Promise<void>
}

export function useInfiniteCenterNotificationsQuery(
  receiver: string,
  fetchAll: boolean = false,
) {
  const dispatch = useAppDispatch()

  const queryCacheKey: FetchLatestNotificationRequest = {
    receiver,
    limit: NOTIFICATION_LOAD_LIMIT,
    order: 'DESC',
    readState: 'UNREAD',
    cursor: {},
  }
  const baseResult = useFetchLatestNotificationsQuery(queryCacheKey)

  const [trigger, nextResult] = useLazyFetchLatestNotificationsQuery()

  const isBaseReady = useRef(false)
  const isNextDone = useRef(true) // default has true since it's not executing
  const next = useRef<null | Record<string, string>>(null)

  const [hasNext, setHasNext] = useState(false)

  // Check Base Result
  const initialFetchHasNext =
    baseResult.isSuccess &&
    baseResult.data.notifications.length === NOTIFICATION_LOAD_LIMIT

  // Check Next Result
  const nextFetchHasNext =
    nextResult.isSuccess &&
    nextResult.data.notifications.length === NOTIFICATION_LOAD_LIMIT

  // Only Run This For Initial Fetch, and Refetch
  useEffect(() => {
    // Return early if the base result is not successful/refetching/error
    if (!baseResult.isSuccess || baseResult.isFetching || baseResult.isError)
      return

    isBaseReady.current = true

    // There are possibly more records, set up next cursor
    if (initialFetchHasNext) {
      const { id, createdAt } =
        baseResult.data.notifications[NOTIFICATION_LOAD_LIMIT - 1]
      next.current = { id, createdAt }
      setHasNext(true)
    }

    if (fetchAll) fetchNext()
  }, [baseResult.isSuccess, baseResult.isFetching])

  // Next Result
  useEffect(() => {
    // Return early if the next result is not successful/refetching/error
    if (!nextResult.isSuccess || nextResult.isFetching || nextResult.isError) {
      return
    }

    // No more records, set next to null
    if (nextResult.data.notifications.length === 0) {
      next.current = null
      return
    }

    // Cursor for next fetching
    let nextResultCursor = null
    if (nextFetchHasNext) {
      const { id, createdAt } =
        nextResult.data.notifications[NOTIFICATION_LOAD_LIMIT - 1]
      nextResultCursor = { id, createdAt }
      setHasNext(true)
    }

    if (
      isBaseReady.current &&
      nextResult.data &&
      nextResultCursor !== next.current // avoid fetching duplicates
    ) {
      next.current = nextResultCursor
      const newItems = nextResult.data.notifications
      dispatch(
        apiSlice.util.updateQueryData(
          'fetchLatestNotifications',
          queryCacheKey,
          (data) => {
            data.notifications = [...data.notifications, ...newItems]
          },
        ),
      )
    }
  }, [nextResult])

  const fetchNext = async () => {
    if (!isBaseReady.current || !isNextDone.current || !next.current) return
    try {
      isNextDone.current = false
      await trigger({
        receiver,
        limit: NOTIFICATION_LOAD_LIMIT,
        order: 'DESC',
        readState: 'UNREAD',
        cursor: next.current,
      })
      setHasNext(false)
    } catch (e) {
      console.log(e)
    } finally {
      isNextDone.current = true
      fetchAll && fetchNext()
    }
  }

  const refetch = async () => {
    isBaseReady.current = false
    isNextDone.current = true
    next.current = null
    await baseResult.refetch() // restart with a whole new refetching
  }

  return {
    data: baseResult.data,
    error: baseResult.error,
    isSuccess: baseResult.isSuccess,
    isError: baseResult.isError,
    isLoading: baseResult.isLoading,
    isFetching: baseResult.isFetching,
    errorNext: nextResult.error,
    isErrorNext: nextResult.isError,
    isFetchingNext: nextResult.isFetching,
    hasNext,
    fetchNext,
    refetch,
  }
}
