export default function BellIcon({ width = '25', height = '24' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7031 2C16.8557 2 20.2464 5.27577 20.389 9.43292L20.3933 9.69023V13.8911L21.8083 17.1272C21.8791 17.2891 21.9157 17.4639 21.9157 17.6407C21.9157 18.3485 21.3419 18.9224 20.634 18.9224L15.7792 18.9239C15.7792 20.6228 14.402 22 12.7031 22C11.0649 22 9.7258 20.7194 9.63224 19.1047L9.62655 18.9216L4.78203 18.9224C4.60635 18.9224 4.43254 18.8863 4.2714 18.8163C3.62213 18.5342 3.32442 17.7793 3.60644 17.13L5.01288 13.8921V9.69012C5.01349 5.42995 8.44999 2 12.7031 2ZM14.2407 18.9216L11.1651 18.9239C11.1651 19.7733 11.8537 20.462 12.7031 20.462C13.5026 20.462 14.1596 19.852 14.2341 19.072L14.2407 18.9216ZM12.7031 3.53805C9.29873 3.53805 6.55141 6.28013 6.55092 9.69023V14.2117L5.17285 17.3843H20.2421L18.8553 14.2127L18.8554 9.70341L18.8516 9.4725C18.7377 6.15701 16.0269 3.53805 12.7031 3.53805Z"
        fill="#202020"
      />
    </svg>
  )
}
