export const FONT_WEIGHT = {
  SMALL: 300,
  REGULAR: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800,
}

export const BREAK_POINTS = {
  DESKTOP: 1400,
  DESKTOP_RESIZE: 1300,
  TABLET: 1024,
  MOBILE: 800,
  MEDIUM_MOBILE: 450,
  SMALL_MOBILE: 350,
  TABLET_VERTICAL_MIN: 834,
  TABLET_VERTICAL_MAX: 1194,
}

export const COLOR = {
  AURORAL: '#F2467A',
  BISCUIT: '#FEEFCC',
  BLUE: '#3779E4',
  BLUE_100: '#BBDEFB',
  BLUE_CARIBBEAN: '#14C9D5',
  BLUE_MARINA: '#1ED5C9',
  BLUE_PICTON: '#33ABDF',
  BLUE_PALATINATE: '#2B4DC1',
  BTN_BLUE_PRIMARY_ACTIVE: '#2363CA',
  BLUE_WIFI: '#619BF9',
  BLUE_BORDER: '#E1F1FF',
  BLUE_BORDER_100: '#2196F3',
  BLACK_BLUE: '#041532',
  CONCRETE: '#F3F3F3',
  CORAL_DUSK: '#FFB489',
  DODGER_BLUE: '#1E90FF',
  EMERALD: '#DBF5DE',
  GAINSBORO: '#E1E1E1',
  GRAY: '#8F8F8F',
  GRAY_LIGHT: '#EAEAEA',
  GREEN: '#83F18E',
  GREEN_EMERALD: '#4BD382',
  GREEN_EMERALD_40: 'rgba(75, 211, 130, 0.4)',
  GREEN_LIZARD: '#98BA61',
  GREEN_LEAF: '#83D9D1',
  GREEN_MINT: '#369F4F',
  GREEN_LIGHT: '#28cd5633',
  GOLDEN_STAFF: '#E9F989',
  HAWKES_BLUE: '#CAE0FB',
  LILA: '#725AD6',
  LIGHT_BLUE: '#52A2EB',
  LIGHT_GRAY: '#CFCFCF',
  LIGHT_YELLOW: '#FEEFC1',
  DARK_YELLOW: '#FF9A02',
  MALACHITE: '#28CA92',
  MINT_CREAM: '#EFF7FF',
  MONA_LISA: '#FF8989',
  MIDSUMMER_FIELD: '#8BD248',
  MONARCH_WING: '#FF8A21',
  NEON_CARROT: '#DF8633',
  DARK_ORANGE: '#FF600F',
  ORANGE: '#FABE09',
  PERANO: '#B0CDF3',
  PINK_DARK: '#DF325C',
  PINK_LIGHT: '#F8446F',
  PINK_POWDER: '#FFE8EF',
  PINK_HOT: '#FE6BA0',
  PINK_HOT_40: 'rgba(254, 107, 160, 0.4)',
  POLAR: '#E4F8F6',
  PURPLE: '#BE6BC6',
  PUERTO_RICO: '#4CCDC1',
  STRAWBERRY: '#F2466F',
  SUMMER_SKY: '#F24651',
  TEXT_BLACK: '#313131',
  TEXT_BOLD: '#272727',
  TEXT_GRAY: '#838383',
  TEXT_LIGHT: '#202020',
  TEXT_DISABLED: 'rgba(0, 0, 0, 0.38)',
  TRANSPARENT: 'transparent',
  WHITE: '#FFFFFF',
  WATERMELON: '#FF6D90',
  WHITE_SMOKE: '#F7F7F7',
  WHISPER: '#E7E7E7',
  WHISPER_MIDDLE: '#F0F0F0',
  GRAY_MEDIUM: '#585858',
  TEXT_LIGHT_50: '#20202080',
  MAIN_BLACK_50: 'rgba(32, 32, 32, 0.5)',
  BLACK_100: 'rgba(20, 20, 20, 1)',
  BLACK_75: 'rgba(20, 20, 20, 0.75)',
  BLACK_56: '#0000008F',
  BLACK_50: 'rgba(20, 20, 20, 0.5)',
  BLACK_25: 'rgba(20, 20, 20, 0.25)',
  BLACK_24: '#00000024',
  BLACK_20: 'rgba(20, 20, 20, 0.2)',
  BLACK_10: 'rgba(20, 20, 20, 0.1)',
  BLACK_4: 'rgba(20, 20, 20, 0.04)',
  BLACK_EERIE: '#1E1E1E',
  GRAY_BORDER: '#E2E4E7',
  GINGER_LIGHT: '#fef7d9',
  BROWN: '#9c521f',
}

export const GRADIENT = {
  BLUE: 'linear-gradient(180deg, #3982F7 0%, #286CDA 100%)',
}

export const FONT = {
  SMALL: `font-weight: ${FONT_WEIGHT.SMALL};`,
  REGULAR: `font-weight: ${FONT_WEIGHT.REGULAR};`,
  MEDIUM: `font-weight: ${FONT_WEIGHT.MEDIUM};`,
  SEMI_BOLD: `font-weight: ${FONT_WEIGHT.SEMI_BOLD};`,
  BOLD: `font-weight: ${FONT_WEIGHT.BOLD};`,
  EXTRA_BOLD: `font-weight: ${FONT_WEIGHT.EXTRA_BOLD};`,
}

export const DEVICE = {
  // DESKTOP: `@media (min-width: ${BREAK_POINTS.DESKTOP}px)`,
  DESKTOP_RESIZE: `@media (max-width: ${BREAK_POINTS.DESKTOP_RESIZE}px)`,
  TABLET_UP: `@media (min-width: ${BREAK_POINTS.TABLET + 1}px)`,
  TABLET_DOWN: `@media (max-width: ${BREAK_POINTS.TABLET}px)`,
  MOBILE_UP: `@media (min-width: ${BREAK_POINTS.MOBILE + 1}px)`,
  LOW_SCREEN: '@media (max-height: 700px)',
  DESKTOP: `@media (width >= 1400px)`,
  TABLET_HORIZONTAL: `@media (1194px < width < 1400px) `,
  TABLET_VERTICAL: `@media (width <= 1194px)`,
}

export const EFFECT = {
  ELLIPSIS: `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
  HOVER: `
      cursor: pointer;
      :hover {
        transition: all ease-out 200ms;
        filter: brightness(0.97);
      }`,
  PRESS: `
      :active {
        transition: all ease-out 200ms;
        filter: brightness(0.8);
      }`,
  FOCUS: `
      :focus {
        outline: 0;
      }
    `,
}
