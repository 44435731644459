export default function RadioOn() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="24"
        height="24"
        transform="translate(0.583984)"
        fill="white"
      />
      <circle
        cx="12.584"
        cy="12"
        r="11.5"
        stroke="#202020"
        strokeOpacity="0.25"
      />
      <circle cx="12.584" cy="12" r="8" fill="#3779E4" />
    </svg>
  )
}
