import {
  Icon,
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import SelectControl from '../../icons/SelectControl'
import { useTranslation } from 'react-i18next'

import { COLOR } from '../../common/styles'
import { LANGUAGE_OPTIONS } from '../../common/constants'

interface Props {
  showSubtitle?: boolean
  selectedLanguage: string
  setSelectedLanguage: (selectedLanguage: string) => void
}

export default function LanguageSetting({
  showSubtitle = false,
  selectedLanguage,
  setSelectedLanguage,
}: Props) {
  const { t } = useTranslation()

  return (
    <Stack gap="6px">
      {showSubtitle && (
        <Typography color={(theme) => theme.palette.text.secondary}>
          {t('Language')}
        </Typography>
      )}
      <StyledSelect
        value={selectedLanguage}
        MenuProps={{
          disablePortal: true,
        }}
        onChange={(event) => {
          setSelectedLanguage(event.target.value as string)
        }}
        IconComponent={() => (
          <Icon className="MuiSelect-icon" sx={{ alignContent: 'center' }}>
            <SelectControl color={COLOR.MAIN_BLACK_50} />{' '}
          </Icon>
        )}
      >
        {Object.keys(LANGUAGE_OPTIONS).map((language: string) => (
          <MenuItem
            sx={{ height: '44px' }}
            key={language}
            value={LANGUAGE_OPTIONS[language]}
          >
            <Typography> {t(language)} </Typography>
          </MenuItem>
        ))}
      </StyledSelect>
      <Typography
        variant="caption"
        color={(theme) => theme.palette.text.secondary}
      >
        {t("Choose the language you'd like to use")}
      </Typography>
    </Stack>
  )
}

const StyledSelect = styled(Select)({
  'padding': '12px',
  '& .MuiMenu-paper': {
    'padding': '8px',
    '& .MuiMenuItem-root:hover': {
      backgroundColor: '#F7F7F7',
    },
    '& .MuiMenuItem-root.Mui-selected': {
      'backgroundColor': '#3779E4',
      '& .MuiTypography-root': {
        color: '#fff !important',
      },
    },
  },
})
