import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

function SeachIcon({ color = '#000', width = '18', height = '19' }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill={color}
      style={{
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        fillOpacity: '1',
      }}
    >
      <path d="M7.5 1.93457C10.6066 1.93457 13.125 4.45297 13.125 7.55957C13.125 8.84218 12.6957 10.0245 11.973 10.9708L15.5303 14.5292C15.8232 14.8221 15.8232 15.297 15.5303 15.5899C15.26 15.8603 14.8345 15.8811 14.5403 15.6523L14.4697 15.5899L10.9112 12.0326C9.96496 12.7553 8.78261 13.1846 7.5 13.1846C4.3934 13.1846 1.875 10.6662 1.875 7.55957C1.875 4.45297 4.3934 1.93457 7.5 1.93457ZM7.5 3.43457C5.22183 3.43457 3.375 5.2814 3.375 7.55957C3.375 9.83774 5.22183 11.6846 7.5 11.6846C9.77817 11.6846 11.625 9.83774 11.625 7.55957C11.625 5.2814 9.77817 3.43457 7.5 3.43457Z" />
    </svg>
  )
}

export default SeachIcon
