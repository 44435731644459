function DatePickerCalendar({ width = '18', height = '20' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00012 9H4.00012V11H6.00012V9ZM10.0001 9H8.00012V11H10.0001V9ZM14.0001 9H12.0001V11H14.0001V9ZM16.0001 2H15.0001V0H13.0001V2H5.00012V0H3.00012V2H2.00012C0.890122 2 0.0101221 2.9 0.0101221 4L0.00012207 18C0.00012207 19.1 0.890122 20 2.00012 20H16.0001C17.1001 20 18.0001 19.1 18.0001 18V4C18.0001 2.9 17.1001 2 16.0001 2ZM16.0001 18H2.00012V7H16.0001V18Z"
        fill="black"
        fillOpacity="0.56"
      />
    </svg>
  )
}

export default DatePickerCalendar
