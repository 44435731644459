import { Stack, Box, Typography, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { UserPortal } from 'platform'

export type ProductsDrawerProps = {
  portals: UserPortal[]
  currentPortalId: number | string
}

export function ProductsDrawer({
  portals,
  currentPortalId,
}: ProductsDrawerProps) {
  return (
    <Stack padding="15px">
      <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap="8px">
        {portals.map((product) => (
          <ProductItem
            key={product.id}
            product={product}
            isActive={currentPortalId === product.id}
          />
        ))}
      </Box>
    </Stack>
  )
}

export type ProductItemProps = {
  product: UserPortal
  isActive: boolean
}

function ProductItem({ product, isActive }: ProductItemProps) {
  return (
    <AppButton
      onClick={() => {
        window.open(product.portalUrl, '_blank')
      }}
      // eslint-disable-next-line eqeqeq
      className={isActive ? 'Mui-active' : ''}
    >
      <Stack
        sx={{ minHeight: '75px', maxWidth: '85px' }}
        direction="column"
        alignItems="center"
        justifyContent="space-start"
        gap="3px"
      >
        <img src={product.portalIconUrl} alt={product.name} />
        <Typography variant="body2" textTransform="none">
          {product.name}
        </Typography>
      </Stack>
    </AppButton>
  )
}

const AppButton = styled(Button)(({ theme }) => ({
  'gap': '0px',
  'width': '130px',
  'height': '95px',
  'padding': '15px 2px',

  'img': {
    filter:
      'invert(0%) sepia(1%) saturate(7241%) hue-rotate(197deg) brightness(82%) contrast(75%)',
    width: '20px',
    height: '20px',
  },

  '&.Mui-active, &:hover': {
    '.MuiTypography-root': {
      color: theme.palette.primary.main,
    },
    'img': {
      filter:
        'invert(38%) sepia(96%) saturate(1014%) hue-rotate(199deg) brightness(91%) contrast(95%)',
    },
  },

  '&:hover': {
    backgroundColor: '#EFF7FF',
  },

  '&.Mui-active': {
    backgroundColor: theme.palette.primary.light,
  },
}))
