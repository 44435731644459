import dayjs, { Dayjs } from 'dayjs'
import { EXPLORER_FEATURES, isFeatureEnabled } from 'platform'
import {
  DEFAULT_TIMEZONE,
  DEFAULT_TIMEZONE_DISPLAY,
} from 'ui/src/common/constants'

/**
 * @param {string} timestamp: seconds
 */
export function formatNotificationDisplayTime(timestamp: string) {
  const timestampNumber = parseInt(timestamp, 10)
  if (!isFeatureEnabled(EXPLORER_FEATURES.TIMEZONE_CONFIG)) {
    return dayjs.unix(timestampNumber).utc().format('MMMM D [at] HH:mm (UTC)')
  }

  const userTimezone = localStorage.getItem('selectedTimezone') || 'UTC'
  const now = dayjs().tz(userTimezone)
  const date = dayjs.unix(timestampNumber).tz(userTimezone)

  let formattedDate: string
  const diffDays = now.startOf('day').diff(date.startOf('day'), 'day')

  switch (diffDays) {
    case 0: // Today
      formattedDate = date.format(`[Today at] HH:mm`)
      break
    case 1: // Yesterday
      formattedDate = date.format('[Yesterday at] HH:mm')
      break
    default: // 2 days ago or more
      formattedDate = date.format('MMMM D, YYYY [at] HH:mm')
      break
  }

  return formattedDate
}

export const formatTimezone = (
  timeZone: string,
  offsetDisplay: 'hour' | 'number' | 'none',
) => {
  if (timeZone === DEFAULT_TIMEZONE) {
    return DEFAULT_TIMEZONE_DISPLAY
  }

  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeZoneName: 'shortOffset',
  })

  let offset = formatter
    .formatToParts()
    .find((part) => part.type === 'timeZoneName')!
    .value.substring(3)

  if (!offset) {
    offset += '+0'
  }

  if (offsetDisplay === 'hour') offset += ' hours'
  if (offsetDisplay === 'number' && !offset.includes(':')) offset += ':00'

  return `UTC ${offset}`
}

/**
 * @param {string} timestamp: seconds
 */
export function formatTimeWithUserTimezone(
  timestamp: string,
  format: string,
): string {
  const userTimezone = localStorage.getItem('selectedTimezone') || 'UTC'
  const timestampNumber = parseInt(timestamp, 10)
  const formattedDate = dayjs
    .unix(timestampNumber)
    .tz(userTimezone)
    .format(format)
  return formattedDate
}

export const combineDateTimeWithUserTimezone = (
  dateObj: Dayjs,
  timeObj: Dayjs,
) => {
  const dateString = dateObj.format('YYYY-MM-DD')
  const timeString = timeObj.format('HH:mm:ss')

  // Retrieve the user's timezone from local storage
  const selectedTimezone = localStorage.getItem('selectedTimezone') || 'UTC'

  const userDateTime = dayjs.tz(`${dateString}T${timeString}`, selectedTimezone)

  return userDateTime.utc()
}
