import { Box, Stack, styled } from '@mui/material'
import {
  GridColumnHeaderParams,
  GridFilterModel,
  GridPaginationModel,
  GridRowHeightParams,
  GridRowId,
  GridRowParams,
  GridSortDirection,
  GridSortModel,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useState } from 'react'
import { DataGridProTable } from 'ui'
import { useSetNotificationFilter } from '../../../redux/hooks/notification-hooks'
import { usePatchTableNotificationsMutation } from '../../../redux/slices/api-slice'
import {
  DEFAULT_ROW_HEIGHT,
  EXPANDED_ROW_HEIGHT,
  TABLE_PAGE_SIZE_OPTIONS,
} from '../common/constants'
import {
  createNotificationColumns,
  transformFilterModel,
  transformPaginationModel,
  transformSortModel,
} from '../common/table-helpers'
import {
  NotificationActionType,
  NotificationTableRow,
} from '../types/common-types'
import NotificationBanner from './NotificationBanner'
import { useTranslation } from 'react-i18next'
import { DelightIcon } from './common'
import { useCommon } from '../../../context/commonContext'

interface NotificationTableProps {
  notificationRows: NotificationTableRow[]
  isLoading: boolean
  notificationCount: number
}

export default function NotificationTable({
  notificationRows,
  isLoading,
  notificationCount,
}: NotificationTableProps) {
  const { productAccess, productNameIDMap } = useCommon()
  // Grid
  const apiRef = useGridApiRef()
  const [rowIdExpanded, setRowIdExpanded] = useState<null | GridRowId>(null)
  const [markReadUnread, { isLoading: isUpdating }] =
    usePatchTableNotificationsMutation()

  const initialState = {
    pagination: {
      paginationModel: {
        pageSize: TABLE_PAGE_SIZE_OPTIONS[0],
        page: 0,
      },
    },
    sorting: {
      sortModel: [{ field: 'deliveryDate', sort: 'desc' }],
    },
  }

  const { setNotificationFilter, setFilterPagination } =
    useSetNotificationFilter()

  // Todo: Optimization Room here for height calculation
  const checkIfTitleEclipsed = (params: GridRowHeightParams) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (!context) {
      return false
    }
    context.font = params.model.readState ? '400 16px Inter' : '700 16px Inter'
    const letterSpacing = params.model.readState ? 0.5 : 0.25
    const totalLetterSpacing = (params.model.title.length - 1) * letterSpacing
    const text = context.measureText(params.model.title)
    const titleWidth = text.width + totalLetterSpacing
    const columnWidth = apiRef.current.getColumn('message').computedWidth || 504
    const titleEclipsed = titleWidth > columnWidth
    return titleEclipsed
  }

  const getRowHeight = (params: GridRowHeightParams) => {
    const titleEclipsed = checkIfTitleEclipsed(params)
    const expanded = params.id === rowIdExpanded
    if (expanded) {
      return EXPANDED_ROW_HEIGHT + (titleEclipsed ? 24 : 0)
    }
    return DEFAULT_ROW_HEIGHT
  }

  const handleExpandClick = (id: GridRowId) => {
    const currentlyExpanded = id === rowIdExpanded
    setRowIdExpanded(currentlyExpanded ? null : id)
  }

  // GridRowId is initialized as string when constructing rows
  const handleToggleRead = (id: string, nextReadState: boolean) => {
    const markReadUnreadRequest = {
      id: [id as string],
      readState: nextReadState,
    }
    markReadUnread(markReadUnreadRequest)
  }

  const handleColumnHeaderClick = (
    params: GridColumnHeaderParams,
    event: MuiEvent<React.MouseEvent>,
  ) => {
    const target = event.target as HTMLElement
    event.defaultMuiPrevented = true
    if (target.closest('.MuiDataGrid-iconButtonContainer')) {
      const currentSortModel = apiRef.current.getSortModel()
      const currentSort = currentSortModel.find(
        (model) => model.field === params.field,
      )
      // If currentSort is a different column, currentSort == undefined, next click should be desc
      const nextSortDirection: GridSortDirection =
        currentSort?.sort === 'desc' ? 'asc' : 'desc'
      apiRef.current.sortColumn(params.field, nextSortDirection)
    }
  }

  // Note: The calendar dispatches its own updates. Server-side filtering will detect these changes,
  // but transformFilterModel(model) will be called without updating the time.
  const handleOnFilterModelChange = (model: GridFilterModel) => {
    const updatedHeaderFilter = transformFilterModel(model, productNameIDMap)
    setNotificationFilter(updatedHeaderFilter)
  }

  const handleOnSortModelChange = (model: GridSortModel) => {
    const updatedSortFilter = transformSortModel(model)
    setNotificationFilter(updatedSortFilter)
  }

  const handleOnPaginationModelChange = (model: GridPaginationModel) => {
    const updatedPaginationFilter = transformPaginationModel(model)
    if (updatedPaginationFilter.limit) {
      apiRef.current.setPage(0)
      setNotificationFilter(updatedPaginationFilter)
    } else {
      // Update Page Only
      setFilterPagination(updatedPaginationFilter)
    }
  }

  const cols = createNotificationColumns(
    rowIdExpanded,
    handleToggleRead,
    productAccess,
    Object.values(NotificationActionType),
  )

  return (
    <TableContainer className="notification-table-container">
      <DataGridProTable
        apiRef={apiRef}
        rows={notificationRows}
        cols={cols}
        initialState={initialState}
        getRowHeight={getRowHeight}
        loading={isLoading}
        onRowClick={(params: GridRowParams) => handleExpandClick(params.id)}
        // Enable Header Filter
        headerFilters
        // Filter
        filterMode="server"
        onFilterModelChange={handleOnFilterModelChange}
        filterDebounceMs={1000}
        // Pagination
        rowCount={notificationCount}
        pagination
        paginationMode="server"
        pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
        onPaginationModelChange={handleOnPaginationModelChange}
        onColumnHeaderClick={handleColumnHeaderClick}
        sortingMode="server"
        onSortModelChange={handleOnSortModelChange}
        headerFilterHeight={64}
        noRowsOverlay={CustomFallBackOverlay}
        noResultsOverlay={CustomFallBackOverlay}
        additionalGridProps={additionalGridProps}
      />
    </TableContainer>
  )
}

function CustomFallBackOverlay() {
  const { t } = useTranslation()

  return (
    <Stack
    height="100%"
    width="100%"
    overflow="hidden"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <NotificationBanner
      icon={<DelightIcon />}
      description={t('notification.allCaughtUp')}
    />
  </Stack>
  )
}

const additionalGridProps = {
  sx: {
    // Cell
    '& .MuiDataGrid-cell:not([data-field="message"])': {
      padding: '16px',
      lineHeight: 'unset', // Reset to default, prevent text taking up extra width
    },
    // sorting Icon Visible
    '& .MuiDataGrid-iconButtonContainer': {
      visibility: 'visible',
      cursor: 'pointer',
    },
    '& .MuiDataGrid-sortIcon': {
      opacity: 'inherit !important',
    },
    'cursor': 'pointer',
  },
}

const TableContainer = styled(Box)`
  display: flex;
  flex: 1;
  overflow: hidden;
`
