import { Stack, Box, Typography, Link } from '@mui/material'
import { BellIcon } from 'ui'

export type Notification = {
  id: string
  type: string
  application: string
  sender: string
  message: string
  link: string
  data: Record<string, any>
  read?: boolean
  sentTime: Date
}

// type NotificationMenuProps = {
//   notifications: Notification[]
//   total: number
// }

export function NotificationMenu() {
  const notifications: Notification[] = [
    // {
    //   id: '1',
    //   type: 'type',
    //   application: 'uam',
    //   sender: 'sender',
    //   message: '"Creating operators" permissions have been some what',
    //   link: 'www.google.com',
    //   data: {},
    //   read: false,
    //   sentTime: new Date(),
    // },
  ]
  return (
    <Stack padding="5px 15px">
      <Box height="29px" width="114px" padding="8px 0 3px">
        <Typography variant="body1bold">Notifications</Typography>
      </Box>
      {notifications.length > 0 ? (
        notifications.map((notification, i) => (
          <NotificationMenuItem notification={notification} key={i} />
        ))
      ) : (
        <Stack
          direction="row"
          height="34px"
          gap="10px"
          sx={{
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              svg: {
                width: '18px',
                height: '18px',
              },
            }}
          >
            <BellIcon />
          </Box>
          <Typography variant="body1">
            You do not have any notifications.
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}

function NotificationMenuItem({
  notification,
}: {
  notification: Notification
}) {
  const formatter = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'long',
    timeStyle: 'short',
    hour12: false,
  })
  const timestamp = formatter.format(notification.sentTime).replace('at ', '')

  return (
    <Stack padding="15px 0" gap="10px" width="424px" height="76px">
      <Stack direction="row" justifyContent="space-between" height="18px">
        <Stack direction="row" gap="10px">
          <Box>icon</Box>
          <Box>{notification.application}</Box>
        </Stack>
        <Typography variant="body2" color="rgba(32, 32, 32, 0.5)">
          {/* June 4, 2023 10:05 */}
          {timestamp}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" height="18px">
        <Typography
          variant="body2"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {notification.message}
        </Typography>

        <Link href={notification.link} underline="hover" color="primary">
          <Typography width="100px" variant="body2" color="#3779E4">
            View More
          </Typography>
        </Link>
      </Stack>
    </Stack>
  )
}
