import { styled } from '@mui/material'
import Stack from '@mui/material/Stack'
import NotificationTable from '../components/NotificationTable'
import NotificationHeader from '../components/NotificationHeader'
import {
  useMyFetchNotificationsCountQuery,
  useMyFetchTableNotificationsQuery,
  useSetNotificationFilter,
} from '../../../redux/hooks/notification-hooks'
import { transformNotificationDataToRows } from '../common/table-helpers'
import { NotificationTableRow } from '../types/common-types'
import { useEffect, useMemo, useState } from 'react'
import { initialState } from '../../../redux/slices/notification-slice'
import { useAppSelector } from '../../../redux/hooks/redux-hooks'
import { parseNotificationCount } from '../common/notification-helpers'
import { useCommon } from '../../../context/commonContext'

// 1. Refresh - It will refetch without using the cache
// 2. Unmount Remount - Needs to refetch when offset is 0 and endAt is 0
// 3. Pagination - Does not need to refetch when offset is 0 and endAt is 0

function NotificationPage() {
  const { userEmail } = useCommon()
  const { page, readState, limit } = useAppSelector(
    (state) => state.notification,
  )
  const [currentEndAt, setCurrentEndAt] = useState(Date.now())
  const tableNotifications = useAppSelector(
    (state) => state.notification.tableNotifications,
  )
  const notifications = tableNotifications[page] || []
  const skipCurrentPage = !!tableNotifications[page]
  const {
    isLoading: isNotificationLoading,
    isFetching: isNotificationFetching,
  } = useMyFetchTableNotificationsQuery(
    userEmail,
    currentEndAt,
    page,
    skipCurrentPage,
  )

  const {
    data: notificationCountData,
    isLoading: isNotificationCountLoading,
    isSuccess: isNotificationCountSuccess,
    isFetching: isNotificationCountFetching,
  } = useMyFetchNotificationsCountQuery(userEmail, currentEndAt)

  const notificationTableRows = transformNotificationDataToRows(notifications)

  const notificationCount = isNotificationCountSuccess
    ? parseNotificationCount(readState, notificationCountData)
    : 0

  const unreadNotificationIds = useMemo(
    () =>
      notificationTableRows.reduce(
        (acc: string[], notification: NotificationTableRow) => {
          if (!notification.readState) {
            acc.push(notification.id)
          }
          return acc
        },
        [],
      ),
    [notificationTableRows],
  )
  
  const skipNextPage = !tableNotifications[page] || !!tableNotifications[page + 1] || tableNotifications[page].length < limit
  useMyFetchTableNotificationsQuery(
    userEmail,
    currentEndAt,
    page + 1,
    skipNextPage,
  )

  const { setNotificationFilter } = useSetNotificationFilter()

  useEffect(() => {
    return () => {
      // Reset Filters, cursor will be resetted to 0 since page is 0 upon remount
      setNotificationFilter(initialState)
    }
  }, [])

  return (
    <PageContainer>
      <NotificationHeader
        notificationIds={unreadNotificationIds}
        setCurrentEndAt={setCurrentEndAt}
      />
      <NotificationTable
        isLoading={
          isNotificationLoading ||
          isNotificationFetching ||
          isNotificationCountLoading ||
          isNotificationCountFetching
        }
        notificationRows={notificationTableRows}
        notificationCount={notificationCount}
      />
    </PageContainer>
  )
}

export default NotificationPage

const PageContainer = styled(Stack)(() => ({
  boxSizing: 'border-box',
  width: 'calc(100vw - 95px)',
  height: 'calc(100vh - 75px)',
  marginTop: '75px', // 75px is the height of the top bar
  marginLeft: '95px', // 95px is the width of the side bar
  background: '#FFF',
  position: 'fixed',
}))
