import {
  gridFilterModelSelector,
  GridRenderHeaderFilterProps,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro'
import { DateRange } from '@mui/x-date-pickers-pro/models'
import dayjs, { Dayjs } from 'dayjs'
import { useMemo, useState, Dispatch, SetStateAction } from 'react'
import { SingleInputDateRangePicker } from 'ui'
import { SHORTCUTS_ITEMS } from '../common/constants'
import { combineDateTimeWithUserTimezone } from '../common/time-helpers'
import { useTranslation } from 'react-i18next'

const getDefaultFilter = (field: string) => ({ field, operator: 'is' })

export default function CalendarHeaderFilter(
  props: GridRenderHeaderFilterProps,
) {
  const { t } = useTranslation()
  const { colDef } = props
  const apiRef = useGridApiContext()
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector)
  const currentFieldFilters = useMemo(
    () => filterModel?.items?.filter(({ field }) => field === colDef.field),
    [colDef.field, filterModel.items],
  )

  const [dateValue, setDateValue] = useState<DateRange<Dayjs>>([null, null])
  const [appliedDateValue, setAppliedDateValue] = useState<DateRange<Dayjs>>([
    null,
    null,
  ])
  const [startTime, setStartTime] = useState<Dayjs>(dayjs().startOf('day'))
  const [endTime, setEndTime] = useState<Dayjs>(dayjs().endOf('day'))
  const [isOpen, setIsOpen] = useState(false)

  const onAccept = (event: object) => {
    let startFrom = 0
    let endAt = 0
    if (dateValue[0] && startTime) {
      startFrom = combineDateTimeWithUserTimezone(
        dateValue[0],
        startTime,
      ).unix()
    }
    if (dateValue[1] && endTime) {
      endAt = combineDateTimeWithUserTimezone(dateValue[1], endTime).unix()
    }
    apiRef.current.upsertFilterItem({
      ...(currentFieldFilters[0] || getDefaultFilter(colDef.field)),
      operator: 'is',
      id: `${colDef.field}`,
      value: [startFrom, endAt],
    })
    setDateValue(dateValue)
    setAppliedDateValue(dateValue)
  }

  const onCloseDateTimePicker = () => {
    setIsOpen(false)
  }

  return (
    <SingleInputDateRangePicker
      value={appliedDateValue}
      onChange={setDateValue}
      calendars={2}
      inputLabel={t('is')}
      shortcuts={SHORTCUTS_ITEMS}
      timeRange
      startTime={startTime}
      endTime={endTime}
      setStartTime={setStartTime}
      setEndTime={setEndTime}
      maxDate={dayjs()}
      onAccept={onAccept}
      onClose={onCloseDateTimePicker}
      open={isOpen}
      setIsOpen={setIsOpen}
    />
  )
}
