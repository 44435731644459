export default function QuestionIcon({ width = '25', height = '24' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5714 16.2857C13.5714 16.8774 13.0917 17.3571 12.5 17.3571C11.9083 17.3571 11.4286 16.8774 11.4286 16.2857C11.4286 15.694 11.9083 15.2143 12.5 15.2143C13.0917 15.2143 13.5714 15.694 13.5714 16.2857ZM11.0714 9.14292C11.0714 8.7594 11.2001 8.39923 11.4267 8.14425C11.6417 7.90242 11.9795 7.71435 12.5 7.71435C13.4341 7.71435 14.2017 8.67686 13.9499 9.68397C13.8208 10.2002 13.514 10.4948 13.0519 10.9384C13.0108 10.9779 12.9684 11.0186 12.9248 11.0607C12.4044 11.5635 11.7857 12.2335 11.7857 13.4286C11.7857 13.8231 12.1055 14.1428 12.5 14.1428C12.8945 14.1428 13.2143 13.8231 13.2143 13.4286C13.2143 12.8379 13.4667 12.5236 13.9175 12.088C13.9606 12.0463 14.0064 12.003 14.0543 11.9578C14.4928 11.5435 15.1012 10.9687 15.3358 10.0304C15.7983 8.18041 14.4231 6.28578 12.5 6.28578C11.5919 6.28578 10.8583 6.63342 10.359 7.19516C9.87136 7.74376 9.64286 8.45501 9.64286 9.14292C9.64286 9.53741 9.96265 9.85721 10.3571 9.85721C10.7516 9.85721 11.0714 9.53741 11.0714 9.14292ZM2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12ZM12.5 3.42857C7.76613 3.42857 3.92857 7.26613 3.92857 12C3.92857 16.7339 7.76613 20.5714 12.5 20.5714C17.2339 20.5714 21.0714 16.7339 21.0714 12C21.0714 7.26613 17.2339 3.42857 12.5 3.42857Z"
        fill="#202020"
      />
    </svg>
  )
}
