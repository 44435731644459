function Navigation({ width = '20', height = '20' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M10.5 18.8333C15.1023 18.8333 18.8333 15.1024 18.8333 10.5C18.8333 5.89762 15.1023 2.16666 10.5 2.16666C5.89759 2.16666 2.16663 5.89762 2.16663 10.5C2.16663 15.1024 5.89759 18.8333 10.5 18.8333ZM10.5 3.20832C11.1842 3.20832 11.9653 3.82317 12.606 5.19597C12.8325 5.68143 13.0294 6.23935 13.1881 6.85416H7.8118C7.9705 6.23935 8.16739 5.68143 8.39394 5.19597C9.03458 3.82317 9.81575 3.20832 10.5 3.20832ZM7.45 4.75547C7.16208 5.37242 6.92182 6.08015 6.73883 6.85416H4.18379C5.05511 5.3479 6.44977 4.18225 8.11914 3.60586C7.86764 3.95022 7.644 4.33975 7.45 4.75547ZM6.53397 7.89582C6.40333 8.71699 6.33329 9.59305 6.33329 10.5C6.33329 11.4069 6.40333 12.283 6.53397 13.1042H3.68707C3.37775 12.2954 3.20829 11.4175 3.20829 10.5C3.20829 9.58249 3.37775 8.70457 3.68707 7.89582H6.53397ZM6.73883 14.1458C6.92182 14.9198 7.16208 15.6276 7.45 16.2445C7.644 16.6602 7.86763 17.0498 8.11914 17.3941C6.44977 16.8177 5.05511 15.6521 4.18379 14.1458H6.73883ZM7.8118 14.1458H13.1881C13.0294 14.7606 12.8325 15.3185 12.606 15.804C11.9653 17.1768 11.1842 17.7917 10.5 17.7917C9.81575 17.7917 9.03458 17.1768 8.39394 15.804C8.16739 15.3185 7.9705 14.7606 7.8118 14.1458ZM13.4102 13.1042H7.58972C7.45147 12.2972 7.37496 11.4201 7.37496 10.5C7.37496 9.57987 7.45147 8.70283 7.58972 7.89582H13.4102C13.5485 8.70283 13.625 9.57987 13.625 10.5C13.625 11.4201 13.5485 12.2972 13.4102 13.1042ZM14.2611 14.1458H16.8161C15.9448 15.6521 14.5501 16.8177 12.8808 17.3941C13.1323 17.0498 13.3559 16.6602 13.5499 16.2445C13.8378 15.6276 14.0781 14.9198 14.2611 14.1458ZM17.3128 13.1042H14.466C14.5966 12.283 14.6666 11.4069 14.6666 10.5C14.6666 9.59305 14.5966 8.71699 14.466 7.89582H17.3128C17.6222 8.70457 17.7916 9.58249 17.7916 10.5C17.7916 11.4175 17.6222 12.2954 17.3128 13.1042ZM12.8808 3.60586C14.5501 4.18225 15.9448 5.3479 16.8161 6.85416H14.2611C14.0781 6.08015 13.8378 5.37242 13.5499 4.75547C13.3559 4.33975 13.1323 3.95022 12.8808 3.60586Z"
        fill="#212121"
      />
    </svg>
  )
}

export default Navigation
