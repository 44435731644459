import './i18n' // Import early to avoid not loaded in time
import UBOThemeProvider from 'ui/src/mui/UBOThemeProvider'
import {
  Env,
  EXPLORER_FEATURES,
  IAM_ENV,
  isFeatureEnabled,
  PlatformAppProps,
} from 'platform'
import { TopBar } from 'ui'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NotificationPage from './features/notifications/page/NotificationPage'
import NotificationCenter from './features/notifications/components/NotificationCenter'
import NotificationCenterBadge from './features/notifications/components/NotificationCenterBadge'
import './features/notifications/common/dayjs'
import CachingProvider from 'ui/src/components/wrappers/CachingProvider'
import React from 'react'
import { CommonProvider } from './context/commonContext'
import { getProductDisplayName } from './features/notifications/common/permission'

const getHomeUrl = (env: Env) => {
  switch (env) {
    case Env.QAT:
      return IAM_ENV.QAT
    case Env.PREPROD:
      return IAM_ENV.PREPROD
    case Env.STG:
      return IAM_ENV.STG
    case Env.INTG:
      return IAM_ENV.STG
    default:
      return IAM_ENV.PROD
  }
}

export default function Root({ props }: { props: PlatformAppProps }) {
  const { nonce } = props

  const {
    userName,
    logOutURL,
    portalId,
    portals,
    env = Env.QAT, // Todo: Default needs to be removed once product has completed integration
    enableTimezone = false,
    enableMultiLng = false,
    newFont = false,
    userEmail = '', // Todo: Default needs to be removed once product has completed integration
  } = props
  let homeUrl = IAM_ENV.PROD
  if (env) {
    homeUrl = `${getHomeUrl(env)}/?redirectTo=dashboard`
  }

  const userProductAccess: Record<number, string> = portals.reduce(
    (acc: Record<number, string>, portal) => {
      const productDisplayName = getProductDisplayName(portal.id, env)
      acc[portal.id] = productDisplayName || portal.name
      return acc
    },
    {},
  )

  const productNameIDMap: Record<string, number> = portals.reduce(
    (acc: Record<string, number>, portal) => {
      const productDisplayName = getProductDisplayName(portal.id, env)
      const keyName = productDisplayName || portal.name
      acc[keyName] = portal.id
      return acc
    },
    {},
  )

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <CachingProvider nonce={nonce}>
        <UBOThemeProvider>
          <CommonProvider
            userEmail={userEmail}
            productAccess={userProductAccess}
            productNameIDMap={productNameIDMap}
          >
            <BrowserRouter>
              <Provider store={store}>
                <TopBar
                  homeUrl={homeUrl}
                  userName={userName}
                  logOutURL={logOutURL}
                  portalId={portalId}
                  portals={portals}
                  enableTimezone={enableTimezone}
                  enableMultiLng={enableMultiLng}
                  newFont={newFont || false}
                  notificationCenterBadge={
                    isFeatureEnabled(EXPLORER_FEATURES.NOTIFICATION) ? (
                      <NotificationCenterBadge />
                    ) : undefined
                  }
                  notificationCenter={
                    isFeatureEnabled(EXPLORER_FEATURES.NOTIFICATION) ? (
                      <NotificationCenter />
                    ) : undefined
                  }
                />
                <Routes>
                  {/* Todo: 1. use hundomain/all-notifications 2. permission checking */}
                  {isFeatureEnabled(EXPLORER_FEATURES.NOTIFICATION) && (
                    <Route
                      path="/all-notifications"
                      Component={() => <NotificationPage />}
                    />
                  )}
                </Routes>
              </Provider>
            </BrowserRouter>
          </CommonProvider>
        </UBOThemeProvider>
      </CachingProvider>
    </React.Suspense>
  )
}
