import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationFilter } from '../../features/notifications/types/common-types'
import type { AppDispatch, AppState } from '../store'

export const useAppDispatch: () => AppDispatch = useDispatch
export function useAppSelector<Type>(extractor: (state: AppState) => Type) {
  return useSelector(extractor)
}

export const useNotificationFilterSelector = () => {
  const { tableNotifications, tableCursor, ...notificationFilter } =
    useAppSelector((state: AppState) => state.notification)

  return useMemo(() => {
    return notificationFilter as NotificationFilter
  }, [notificationFilter])
}
