import { Box, Chip, List, ListItem } from '@mui/material'
import { DateRange, PickersShortcutsProps } from '@mui/x-date-pickers-pro'
import { Dayjs } from 'dayjs'
import { COLOR } from '../../common/styles'

interface RangeShortcutsProps extends PickersShortcutsProps<DateRange<Dayjs>> {
  currentValue: string
  setCurrentValue: React.Dispatch<React.SetStateAction<string>>
}

export default function RangeShortcuts({
  items,
  onChange,
  isValid,
  changeImportance = 'set',
  currentValue = '',
  setCurrentValue,
}: RangeShortcutsProps) {
  if (items == null || items.length === 0) {
    return null
  }

  const resolvedItems = items.map((item) => {
    const newValue = item.getValue({ isValid })

    return {
      label: item.label,
      onClick: () => {
        onChange(newValue, changeImportance, item)
      },
      disabled: !isValid(newValue),
    }
  })

  return (
    <Box
      sx={{
        gridRow: 2,
        gridColumn: 1,
        height: '350px',
      }}
    >
      <List
        dense
        sx={(theme) => ({
          'display': 'flex',
          'px': theme.spacing(4),
          '& .MuiListItem-root': {
            pt: 0,
            pl: 0,
            pr: theme.spacing(1),
          },
        })}
      >
        {resolvedItems?.map((item) => {
          return (
            <ListItem key={item.label}>
              <Chip
                sx={{
                  backgroundColor:
                    item.label === currentValue ? COLOR.BLUE : '#00000014',
                  color: item.label === currentValue ? COLOR.WHITE : 'inherit',
                }}
                {...item}
                onClick={() => {
                  item.onClick()
                  if (currentValue !== item.label)
                    setCurrentValue(item.label !== 'Reset' ? item.label : '')
                }}
              />
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}
