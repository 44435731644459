import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Badge } from '@mui/material'
import { faBell as solid } from '@fortawesome/pro-solid-svg-icons'
import { faBell as light } from '@fortawesome/pro-light-svg-icons'

interface NotificationBadgeProps {
  unreadCount?: number
}

// Todo: Install fontawesome pro
export default function NotificationBadge({
  unreadCount = 0,
}: NotificationBadgeProps) {
  return unreadCount > 0 ? (
    <BadgeWithUnreadCount count={unreadCount} />
  ) : (
    <FontAwesomeIcon icon={light} style={{ fontSize: '20px' }} />
  )
}

const maximumCount = 20

function BadgeWithUnreadCount({ count }: { count: number }) {
  return (
    <Box>
      <Badge
        badgeContent={count > maximumCount ? '20+' : count} // Use the count parameter
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: '#F2466F',
            color: '#fff',
            top: -4,
            right: -4,
            radius: '100px',
          },
        }}
      >
        <FontAwesomeIcon icon={solid} style={{ fontSize: '20px' }} />
      </Badge>
    </Box>
  )
}
