export default function CheckBoxOff() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.639404" width="24" height="24" rx="6" fill="white" />
      <rect
        x="1.1394"
        y="0.5"
        width="23"
        height="23"
        rx="5.5"
        stroke="#202020"
        strokeOpacity="0.25"
      />
    </svg>
  )
}
