import { COLOR } from '../common/styles'
import Warning from './Warning'

export default {
  title: 'Icons/Common/Warning',
  component: Warning,
}

export function WarningDefault(args: any) {
  return <Warning {...args} />
}

WarningDefault.args = {
  color: COLOR.TEXT_LIGHT,
  width: '26',
  height: '26',
}

WarningDefault.decorators = [
  (Story: any) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '36px',
        height: '36px',
        borderRadius: '8px',
      }}
    >
      <Story />
    </div>
  ),
]

export function WarningError(args: any) {
  return <Warning {...args} />
}

WarningError.args = {
  color: COLOR.STRAWBERRY,
  width: '26',
  height: '26',
}

WarningError.decorators = [
  (Story: any) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '36px',
        height: '36px',
        borderRadius: '8px',
        background: COLOR.WHITE,
      }}
    >
      <Story />
    </div>
  ),
]
