import React from 'react'
import ReactDOM from 'react-dom'
import { ErrorHandling } from 'ui'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err) {
    return <ErrorHandling status={500} message={`{${err}}`} />
  },
})

export const { bootstrap, mount, unmount } = lifecycles
