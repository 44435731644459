import { COLOR } from '../common/styles'

interface Props {
  color?: string
  width?: number | string
  height?: number | string
}

function Warning({ color = COLOR.STRAWBERRY, width = 28, height = 27 }: Props) {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 28 27"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0001 0.166672C21.3641 0.166672 27.3334 6.13734 27.3334 13.5C27.3334 20.8627 21.3641 26.8333 14.0001 26.8333C6.63608 26.8333 0.666748 20.8627 0.666748 13.5C0.666748 6.13734 6.63608 0.166672 14.0001 0.166672ZM14.0001 2.38934C7.87341 2.38934 2.88941 7.37334 2.88941 13.5C2.88941 19.6267 7.87341 24.6107 14.0001 24.6107C20.1267 24.6107 25.1107 19.6267 25.1107 13.5C25.1107 7.37334 20.1267 2.38934 14.0001 2.38934ZM13.9983 16.8363C14.7337 16.8363 15.3298 17.4325 15.3298 18.1679C15.3298 18.9033 14.7337 19.4994 13.9983 19.4994C13.2629 19.4994 12.6667 18.9033 12.6667 18.1679C12.6667 17.4325 13.2629 16.8363 13.9983 16.8363ZM13.9927 6.83334C14.499 6.83293 14.9177 7.20881 14.9843 7.69685L14.9935 7.83254L14.9983 13.8347C14.9987 14.3869 14.5514 14.835 13.9991 14.8355C13.4928 14.8359 13.0741 14.46 13.0075 13.972L12.9983 13.8363L12.9935 7.83414C12.9931 7.28185 13.4404 6.83378 13.9927 6.83334Z"
        fill={color}
      />
    </svg>
  )
}

export default Warning
