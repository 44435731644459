import { COLOR } from 'ui'

export const GOOGLE_MAP_THEME = [
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [{ color: '#d3d3d3' }],
  },
  {
    featureType: 'transit',
    stylers: [{ color: '#808080' }, { visibility: 'off' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ visibility: 'on' }, { color: '#b3b3b3' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [{ color: COLOR.WHITE }],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.fill',
    stylers: [{ visibility: 'on' }, { color: COLOR.WHITE }, { weight: 1.8 }],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#d7d7d7' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [{ visibility: 'on' }, { color: '#ebebeb' }],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [{ color: '#a7a7a7' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [{ color: COLOR.WHITE }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [{ color: COLOR.WHITE }],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [{ visibility: 'on' }, { color: '#efefef' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#696969' }],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [{ visibility: 'on' }, { color: '#737373' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#d6d6d6' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [{ color: '#dadada' }],
  },
  {
    featureType: 'landscape.man_made.building',
    elementType: 'geometry.fill',
    stylers: [{ color: '#E1E1E1' }],
  },
]

export const GOOGLE_MAP_DEFAULT_COORDS = {
  center: {
    lat: 36.287104,
    lng: 3.463557,
  },
  zoom: 3,
}

export const UNIVERSAL_SEARCH_TYPE = {
  USER: 'username',
  DEVICE: 'deviceUUID',
  TRANSACTION: 'gcTransaction',
  MAC: 'macAddress',
}

export const RESULT_USER = [
  {
    field_name: 'user_name',
    context: '1',
    operator_info: [
      {
        operator_name: 'FanDuel Casino PA Own',
        operator_id: 1,
      },
      {
        operator_name: 'Draftkings Sportsbook IL',
        operator_id: 2,
      },
      {
        operator_name: 'RushStreet',
        operator_id: 3,
      },
      {
        operator_name: 'William Hill MI',
        operator_id: 4,
      },
    ],
  },
]

export const RESULT_DEVICE = [
  {
    field_name: 'device_uuid',
    context: 'AAA-BBBB-CCCC',
    operator_info: [
      {
        operator_name: 'Draftkings Sportsbook IL',
        operator_id: 1,
      },
      {
        operator_name: 'DraftKings MI',
        operator_id: 2,
      },
      {
        operator_name: 'DraftKings TN',
        operator_id: 3,
      },
    ],
  },
]

export const RESULT_TRANSACTIONS = [
  {
    field_name: 'gc_transaction',
    context: 'asdfghjk',
    operator_info: [
      {
        operator_name: 'Draftkings Sportsbook IL',
        operator_id: 1,
      },
      {
        operator_name: 'DraftKings MI',
        operator_id: 2,
      },
      {
        operator_name: 'DraftKings TN',
        operator_id: 3,
      },
    ],
  },
]

export const RESULT_MAC = [
  {
    field_name: 'mac_address',
    context: 'AA:BB:CC:DD',
    operator_info: [
      {
        operator_name: 'Draftkings Sportsbook IL',
        operator_id: 1,
      },
    ],
  },
]

export const STATUS_TAG_SIZES = {
  LARGE: 'lg',
  MEDIUM: 'md',
  SMALL: 'sm',
}

export const TRANSACTION_STATUS = {
  PASS: 'Pass',
  FAIL: 'Fail',
}

export const NAVIGATION_CONTROL = {
  PREVIOUS: 'Previous',
  NEXT: 'Next',
}

export const STYLE_TYPE = {
  BOLD: 'bold',
  REGULAR: 'regular',
  MAC_ADDRESS: 'macAddress',
  IP: 'ip',
  DATE: 'date',
  RESPONSE: 'response',
}

export const RETRY_TROUBLESHOOTER_ITEM = {
  YES: 'Yes',
  NO: 'No',
}

export const TYPES_OF_VALUES = {
  OBJECT: 'object',
}

export const TRANSACTION_NAVIGATION_FORMS = {
  transactionDifferent: [
    {
      nextTransaction: {
        show: true,
        id: 'nextTransaction',
        pass: false,
      },
      previousTransaction: {
        show: true,
        id: 'previousTransaction',
        pass: true,
      },
    },
    {
      nextTransaction: {
        show: true,
        id: 'nextTransaction',
        pass: true,
      },
      previousTransaction: {
        show: true,
        id: 'previousTransaction',
        pass: false,
      },
    },
  ],
  transactionSimilar: [
    {
      nextTransaction: {
        show: true,
        id: 'nextTransaction',
        pass: true,
      },
      previousTransaction: {
        show: true,
        id: 'previousTransaction',
        pass: true,
      },
    },
    {
      nextTransaction: {
        show: true,
        id: 'nextTransaction',
        pass: false,
      },
      previousTransaction: {
        show: true,
        id: 'previousTransaction',
        pass: false,
      },
    },
  ],
  firstTransaction: [
    {
      nextTransaction: {
        show: true,
        id: 'nextTransaction',
        pass: false,
      },
    },
    {
      nextTransaction: {
        show: true,
        id: 'nextTransaction',
        pass: true,
      },
    },
  ],
  lastTransaction: [
    {
      previousTransaction: {
        show: true,
        id: 'previousTransaction',
        pass: false,
      },
    },
    {
      previousTransaction: {
        show: true,
        id: 'previousTransaction',
        pass: true,
      },
    },
  ],
}

export const DEFAULT_TIMEZONE = 'UTC'
export const DEFAULT_TIMEZONE_DISPLAY = '(UTC +0 hours)'

export const LANGUAGE_OPTIONS: Record<string, string> = {
  English: 'en',
  Spanish: 'es',
  Portuguese: 'pt',
}

export const DEFAULT_LANGUAGE = 'en'

// Constants are sourced from the QAT and PROD GeoComply Training Lab operator.
// QAT Operator can be remove later after QA check
export const GeocomplyTestingOperators = ['5672-qat_release', '91-us5']

export enum ProfileStatusType {
  BLOCKED = 'Blocked',
  WHITELISTED = 'Whitelisted',
  UNBLOCKED = 'Unblocked',
  WARNING = 'Warning',
  PERMANENTLY_BLOCKED = 'Permanently Blocked',
}

export enum BlockInfoType {
  UNBLOCKED = 'unblocked',
  BLOCKED = 'blocked',
  WHITELISTED = 'whitelisted',
}
