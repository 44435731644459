import { Tooltip } from '@mui/material'
import { StyledRadio } from './common'
import { useTranslation } from 'react-i18next'

type NotificationRadioProps = {
  readState: boolean
  onClick?: () => void
}

export function NotificationRadioWithToolTip({
  readState,
  onClick,
}: NotificationRadioProps) {
  const { t } = useTranslation()
  return (
    <Tooltip
      placement="left"
      title={
        readState ? t('notification.markUnread') : t('notification.markRead')
      }
    >
      <StyledRadio readState={readState} onClick={onClick} />
    </Tooltip>
  )
}
