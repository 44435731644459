import { useEffect, useRef, useState } from 'react'
import {
  Button,
  DialogActions,
  Grid,
  InputAdornment,
  styled,
} from '@mui/material'
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { Dayjs } from 'dayjs'
import {
  LocalizationProvider,
  PickersActionBarProps,
  pickersLayoutClasses,
  PickersShortcutsItem,
  PickersShortcutsProps,
  TimePicker,
} from '@mui/x-date-pickers-pro'
import { DateRange } from '@mui/x-date-pickers-pro/models'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import DatePickerCalendar from '../../icons/DatePickerCalendar'
import RangeShortcuts from './RangeShortcuts'

interface SingleInputDateRangePickerProps {
  value?: DateRange<Dayjs>
  onChange?: (value: DateRange<Dayjs>) => void
  open?: boolean
  onOpen?: () => void
  onClose?: () => void
  onAccept?: (event: object) => void | (() => void)
  calendars?: 1 | 2 | 3
  fieldWidth?: string
  fieldHeight?: string
  calendarWidth?: string
  calendarHeight?: string
  minDate?: Dayjs
  maxDate?: Dayjs
  shortcuts?: PickersShortcutsItem<DateRange<Dayjs>>[]
  inputLabel?: string
  inputReadOnly?: boolean
  placeholder?: string
  timeRange?: boolean
  startTime?: Dayjs | null
  endTime?: Dayjs | null
  setStartTime?: (value: Dayjs) => void
  setEndTime?: (value: Dayjs) => void
  setIsOpen?: (open: boolean) => void
}

export default function SingleInputDateRangePicker({
  value = [null, null],
  onChange,
  open,
  onOpen,
  onClose,
  onAccept,
  calendars = 1,
  fieldWidth,
  fieldHeight,
  calendarWidth,
  calendarHeight,
  minDate,
  maxDate,
  shortcuts,
  inputLabel,
  inputReadOnly,
  placeholder,
  timeRange,
  startTime = null,
  endTime = null,
  setStartTime = () => {},
  setEndTime = () => {},
  setIsOpen = () => {},
}: SingleInputDateRangePickerProps) {
  const [currentValue, setCurrentValue] = useState<DateRange<Dayjs>>(value)
  const [currentShortcut, setCurrentShortcut] = useState('')
  const [applyClicked, setApplyClicked] = useState(false)
  const previousShortcut = useRef<string>('')

  useEffect(() => {
    if (applyClicked) {
      previousShortcut.current = currentShortcut
      setApplyClicked(false)
      if (onAccept) onAccept({})
      if (onClose) onClose()
    }
  }, [applyClicked, currentShortcut, onAccept, onClose])

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const handleClose = () => {
    if (onClose) onClose()
    setCurrentValue(value) // Revert to initial value
    setCurrentShortcut(previousShortcut.current) // Revert to previous shortcut if not applied
  }

  const handleCancel = () => {
    setCurrentValue(value) // Revert to initial value
    setCurrentShortcut(previousShortcut.current) // Revert to previous shortcut if not applied
    setIsOpen(false)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDateRangePicker
        name="allowedRange"
        calendars={calendars}
        fieldWidth={fieldWidth}
        fieldHeight={fieldHeight}
        value={currentValue}
        onChange={(newValue) => {
          setCurrentShortcut('')
          setCurrentValue(newValue as DateRange<Dayjs>)
          if (onChange) onChange(newValue as DateRange<Dayjs>)
        }}
        open={open}
        onOpen={() => {
          if (onOpen) onOpen()
          if (setIsOpen) setIsOpen(true)
        }}
        minDate={minDate}
        maxDate={maxDate}
        onClose={handleClose}
        closeOnSelect={false}
        slots={{
          field: SingleInputDateRangeField,
          actionBar: (props) => (
            <MyActionBar
              {...props}
              timeRange={timeRange}
              startTime={startTime}
              endTime={endTime}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setApplyClicked={setApplyClicked}
              handleCancel={handleCancel}
            />
          ),
          leftArrowIcon: () => (
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{ fontSize: '16px' }}
            />
          ),
          rightArrowIcon: () => (
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ fontSize: '16px' }}
            />
          ),
          shortcuts: (props: PickersShortcutsProps<DateRange<Dayjs>> | any) => (
            <RangeShortcuts
              {...props}
              currentValue={currentShortcut}
              setCurrentValue={setCurrentShortcut}
            />
          ),
        }}
        slotProps={{
          textField: {
            placeholder,
            label: inputLabel,
            variant: 'standard',
            InputProps: {
              readOnly: inputReadOnly,
              endAdornment: (
                <InputAdornment position="end">
                  <DatePickerCalendar />
                </InputAdornment>
              ),
            },
          },
          layout: {
            sx: {
              [`.${pickersLayoutClasses.actionBar}`]: {
                paddingRight: '16px',
                paddingBottom: '8px',
                marginTop: '24px',
                overflow: 'hidden',
              },
            },
          },
          actionBar: {
            actions: ['cancel', 'accept'],
          },
          shortcuts: {
            items: shortcuts,
            changeImportance: 'set',
          },
          desktopPaper: {
            sx: {
              'cursor': 'pointer',
              'width': calendarWidth,
              'height': calendarHeight,
              'borderRadius': '10px',
              '& .MuiList-padding': {
                paddingTop: '24px',
                paddingLeft: '24px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                marginRight: '16px',
              },
              '& .MuiListItem-padding': {
                padding: '0px',
              },
              '& .MuiListItem-root .MuiChip-root': {
                'padding': '4px',
                '& .MuiChip-label': {
                  lineHeight: '18px',
                  paddingLeft: '6px',
                  paddingRight: '6px',
                },
              },
              '& .MuiPickersCalendarHeader-labelContainer': {
                width: '209px', // Customized to look like figma
                marginRight: '0px',
              },
              '& .MuiPickersArrowSwitcher-root': {
                '& .MuiPickersArrowSwitcher-spacer': {
                  width: '18px',
                },
                '& .MuiPickersArrowSwitcher-button': {
                  width: '34px',
                  height: '34px',
                  maxWidth: '34px',
                  flex: 1,
                },
              },
              '& .MuiDayCalendar-header': {
                'paddingLeft': '28px',
                'paddingRight': '28px',
                '& span': {
                  width: '36px',
                  height: '36px',
                  margin: '0px',
                  fontSize: '14px',
                  lineHeight: '20px',
                  letterSpacing: '0.25px',
                  textAlign: 'center',
                },
              },
              '& .MuiDayCalendar-weekContainer': {
                paddingLeft: '28px',
                paddingRight: '28px',
              },
              '& .MuiDateRangePickerDay-root': {
                width: '36px',
                height: '36px',
                display: 'flex',
                justifyContent: 'center',
              },
              '& .MuiDateRangePickerDay-rangeIntervalPreview, & .MuiDateRangePickerDay-root button':
                {
                  boxSizing: 'border-box',
                  width: '100%',
                  height: '100%',
                },
              '& .MuiPickersLayout-shortcuts': {
                overflow: 'unset',
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  )
}

function MyActionBar({
  className,
  timeRange,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  setApplyClicked,
  handleCancel,
}: PickersActionBarProps & {
  timeRange?: boolean
  startTime: Dayjs | null
  endTime: Dayjs | null
  setStartTime: (value: Dayjs) => void
  setEndTime: (value: Dayjs) => void
  setApplyClicked: (clicked: boolean) => void
  handleCancel: () => void
}) {
  const { t } = useTranslation()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DialogActions className={className}>
        <Grid container>
          {timeRange && (
            <Grid
              xs={12}
              item
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '42px',
                paddingBottom: '24px',
                position: 'relative',
              }}
            >
              <TimePicker
                sx={{ height: '56px', width: '275px', boxSizing: 'border-box' }}
                label="From"
                name="From"
                value={startTime}
                onChange={(value) => value && setStartTime(value)}
              />
              <TimePicker
                sx={{ height: '56px', width: '275px', boxSizing: 'border-box' }}
                label="To"
                name="To"
                value={endTime}
                onChange={(value) => value && setEndTime(value)}
              />
              <Divider />
            </Grid>
          )}

          <Grid
            xs={12}
            item
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              sx={{ height: '36px' }}
              onClick={(e: any) => {
                e.preventDefault()
                handleCancel()
              }}
            >
              {t('Cancel')}
            </Button>
            <Button
              variant="contained"
              sx={{
                paddingLeft: '18px',
                paddingRight: '18px',
                height: '36px',
                marginLeft: '8px',
              }}
              onClick={() => setApplyClicked(true)}
            >
              {t('Apply')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </LocalizationProvider>
  )
}

interface DateRangePickerStyleProps {
  fieldWidth?: string
  fieldHeight?: string
}

const StyledDateRangePicker = styled(DesktopDateRangePicker)(
  ({ fieldWidth, fieldHeight }: DateRangePickerStyleProps) => ({
    width: fieldWidth,
    height: fieldHeight,
  }),
)

const Divider = styled('hr')({
  position: 'absolute',
  bottom: '0',
  left: '-20px',
  border: '1px solid #2020201A',
  width: '1000px',
})
