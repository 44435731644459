import {
  faAt,
  faCheck,
  faComment,
  faFileUser,
  faDatabase,
  faCircleInfo,
  faTriangleExclamation,
  faBell,
  faQuestion,
} from '@fortawesome/pro-regular-svg-icons'
import { faCommentCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Chip, CircularProgress, Stack, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { COLOR } from 'ui'
import { useInterpolatedMessage } from '../common/notification-helpers'
import { NotificationActionType } from '../types/common-types'

export const StyledChip = styled(Chip)({
  'borderRadius': '100px',
  'backgroundColor': 'transparent',
  'letterSpacing': '0.4px',
  'padding': '8px 10px',
  'gap': '8px',
  'height': '28px',
  '& .MuiChip-label': {
    padding: '0px',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  '& .MuiChip-icon': {
    margin: '0px',
  },
})

type StyledRadioProps = {
  readState: boolean
}

export const StyledRadio = styled('div')<StyledRadioProps>(({ readState }) => ({
  'maxWidth': '24px',
  'minWidth': '24px',
  'height': '24px',
  'padding': '0px',
  'borderRadius': '50%',
  'border': '1px solid transparent', // Default to transparent border
  'backgroundColor': 'transparent',
  'position': 'relative',
  'content': '""',
  '&:after': readState
    ? {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '24px',
        height: '24px',
        transform: 'translate(-50%, -50%)',
        borderRadius: '50%',
        border: '1.75px solid rgba(20, 20, 20, 0.25)',
      }
    : {},
  '&:hover': {
    'backgroundColor': 'transparent',
    '&:after': readState
      ? {}
      : {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '24px',
          height: '24px',
          transform: 'translate(-50%, -50%)',
          borderRadius: '50%',
          border: '1.75px solid rgba(20, 20, 20, 0.25)',
        },
  },
  '&:before': readState
    ? {}
    : {
        content: '""',
        display: 'block',
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        backgroundColor: '#3779E4',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
}))

interface TypeTagProps {
  label: string
}

export function TypeTag({ label }: TypeTagProps) {
  const { t } = useTranslation()
  let icon

  switch (label) {
    case NotificationActionType.System:
      icon = faDatabase
      break
    case NotificationActionType.Assigned:
      icon = faFileUser
      break
    case NotificationActionType.Mentioned:
      icon = faAt
      break
    case NotificationActionType.Informational:
      icon = faCircleInfo
      break
    case NotificationActionType.Alert:
    case NotificationActionType.ActionRequired:
      icon = faTriangleExclamation
      break
    case NotificationActionType.Reminder:
      icon = faBell
      break
    default:
      icon = faQuestion
  }

  if (
    label === NotificationActionType.Alert ||
    label === NotificationActionType.ActionRequired
  ) {
    return (
      <StyledChip
        variant="outlined"
        sx={{ color: COLOR.DARK_YELLOW, borderColor: COLOR.DARK_YELLOW }}
        label={t(`notification.actionType.${label}`)}
        icon={
          <FontAwesomeIcon
            icon={icon}
            fontSize="16px"
            style={{
              color: COLOR.DARK_YELLOW,
            }}
          />
        }
      />
    )
  }

  return (
    <StyledChip
      variant="outlined"
      color="primary"
      label={t(`notification.actionType.${label}`)}
      icon={<FontAwesomeIcon icon={icon} fontSize="16px" />}
    />
  )
}

interface MarkAllReadButtonProps {
  color?: string
  onClick: () => void
}
export function MarkAllReadButton({ color, onClick }: MarkAllReadButtonProps) {
  const { t } = useTranslation()

  return (
    <Button
      sx={{ color, width: '163px', height: '42px' }}
      startIcon={
        <FontAwesomeIcon
          icon={faCheck}
          style={{ width: '16px', height: '16px' }}
        />
      }
      onClick={onClick}
    >
      {t('notification.markAllRead')}
    </Button>
  )
}

interface MessageWithLinkProps {
  message: string
  link?: string
  linkName?: string
}

export function MessageWithLink({
  message,
  link,
  linkName,
}: MessageWithLinkProps) {
  const interpolatedMessage = useInterpolatedMessage(message, link, linkName)
  return <>{interpolatedMessage}</>
}

export function DelightIcon({
  width = '64px',
  height = '64px',
}: {
  width?: string
  height?: string
}) {
  return (
    <Stack width="112px" height="108px">
      <FontAwesomeIcon
        icon={faComment}
        color="#3779E4"
        style={{
          width, // width height props does not change size
          height,
          zIndex: 0,
          position: 'relative',
          left: '10px',
        }}
      />
      <FontAwesomeIcon
        icon={faCommentCheck}
        color="#3779E4"
        style={{
          width,
          height,
          zIndex: 1,
          position: 'relative',
          left: '45px',
          bottom: '40px',
        }}
      />
    </Stack>
  )
}

export function LoadingSpinner({ height }: { height?: string }) {
  return (
    <Stack height={height} justifyContent="center" alignItems="center">
      <CircularProgress />
    </Stack>
  )
}
