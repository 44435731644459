import { Divider, Stack } from '@mui/material'
import { Virtuoso } from 'react-virtuoso'
import { usePatchCenterNotificationMutation } from '../../../redux/slices/api-slice'
import { NotificationCenterRow } from '../types/common-types'
import { DelightIcon, LoadingSpinner } from './common'
import NotificationBanner from './NotificationBanner'
import NotificationCell from './NotificationCell'
import { useTranslation } from 'react-i18next'

interface NotificationListProps {
  data: NotificationCenterRow[]
  loadNotifications: () => void
  loadingMore: boolean
}

function NotificationList({
  data,
  loadNotifications,
  loadingMore,
}: NotificationListProps) {
  const { t } = useTranslation()
  const [markReadUnread, { isLoading }] = usePatchCenterNotificationMutation()
  return (
    <Virtuoso
      data={data}
      endReached={(index) => loadNotifications()}
      context={{ loadingMore }}
      components={{
        // eslint-disable-next-line object-shorthand
        Footer: Footer,
      }}
      itemContent={(index, item) => (
        <Stack>
          <NotificationCell
            title={item.title}
            createdAt={item.createdAt}
            message={item.message}
            product={item.product}
            type={item.type}
            readState={item.readState}
            link={item.link}
            linkName={item.linkName}
            onClick={() =>
              markReadUnread({ id: [item.id], readState: !item.readState })
            }
          />
          <Divider sx={{ margin: '16px 0px' }} />
          {!loadingMore && index === data.length - 1 && (
            <NotificationBanner
              icon={<DelightIcon />}
              height="256px"
              description={t('notification.allCaughtUp')}
            />
          )}
        </Stack>
      )}
      style={{ height: '592px', width: '600px', overflowX: 'hidden' }} // Adjust the height to show only 5 items at a time
    />
  )
}

function Footer({ context }: { context?: any }) {
  return context?.loadingMore ? <LoadingSpinner height="124px" /> : null
}

export default NotificationList
