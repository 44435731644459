import React from 'react'
import { COLOR } from '../common/styles'

export function Close({
  color = COLOR.TEXT_LIGHT,
  width = '10',
  height = '11',
}) {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 10 11"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.70756 0.917816L9.77297 0.976096C10.0449 1.24807 10.0644 1.67695 9.83125 1.97134L9.77297 2.03676L6.06066 5.74907L9.77297 9.46138C10.0449 9.73335 10.0644 10.1622 9.83125 10.4566L9.77297 10.522C9.501 10.794 9.07211 10.8134 8.77773 10.5803L8.71231 10.522L5 6.80973L1.28769 10.522C1.01572 10.794 0.586833 10.8134 0.292444 10.5803L0.227029 10.522C-0.044943 10.2501 -0.0643696 9.82118 0.16875 9.52679L0.227029 9.46138L3.93934 5.74907L0.227029 2.03676C-0.044943 1.76478 -0.0643695 1.3359 0.16875 1.04151L0.227029 0.976096C0.499002 0.704123 0.927885 0.684697 1.22227 0.917816L1.28769 0.976096L5 4.68841L8.71231 0.976096C8.98428 0.704123 9.41317 0.684697 9.70756 0.917816L9.77297 0.976096L9.70756 0.917816Z"
        fill={color}
      />
    </svg>
  )
}

export default Close
