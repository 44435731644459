import { Link } from '@mui/material'
import React from 'react'
import {
  FetchNotificationCountRequest,
  FetchNotificationCountResponse,
  Notification,
} from '../types/api-types'
import { NotificationFilter } from '../types/common-types'
import { SORT_BY_STRING_MAP } from './constants'

export const constructFetchNotificationRequest = (
  notificationFilter: NotificationFilter,
  receiver: string,
  page: number,
  currentEndAt: number,
  cursor: Record<string, string>,
) => {
  const { endAt } = notificationFilter
  return {
    ...notificationFilter,
    receiver,
    page,
    endAt: endAt || currentEndAt,
    cursor,
  }
}

export const constructFetchNotificationCountRequest = (
  notificationFilter: NotificationFilter,
  currentEndAt: number,
  receiver: string,
) => {
  const { startFrom, endAt, message, product, sender, type } =
    notificationFilter
  const request: FetchNotificationCountRequest = {
    receiver,
    startFrom,
    endAt: endAt || currentEndAt,
  }

  if (message) {
    request.message = message
  }
  if (product) {
    request.product = product
  }
  if (sender) {
    request.sender = sender
  }
  if (type) {
    request.type = type
  }
  return request
}

export const parseNotificationCount = (
  readState: 'ALL' | 'UNREAD',
  notificationCount: FetchNotificationCountResponse,
) =>
  readState === 'ALL' ? notificationCount.count : notificationCount.unreadCount

export const useInterpolatedMessage = (
  message: string,
  link: string | undefined,
  linkName: string | undefined,
) => {
  return React.useMemo(() => {
    if (!message) return null

    const parts = message.split(/(\{\{link\}\})/)

    return parts.map((part, index) => {
      if (part === '{{link}}') {
        if (link) {
          return (
            <Link
              key={index}
              href={link}
              rel="noopener noreferrer"
              target="_blank"
            >
              {linkName || link}
            </Link>
          )
        }
        if (linkName) {
          return <span key={index}>{linkName}</span>
        }
        return null
      }
      return part
    })
  }, [message, link, linkName])
}
export const getInterpolatedMessageString = (
  message: string,
  link: string | undefined,
  linkName: string | undefined,
): string => {
  if (!message) return ''

  const replacementText = linkName || link || ''

  return message.replace(/\{\{link\}\}/g, replacementText)
}

export const constructPaginationCursor = (
  notifications: Notification[],
  sortBy: 'CREATED_AT' | 'PRODUCT' | 'TYPE',
) => {
  let cursor = {}
  const lastNotification = notifications[notifications.length - 1]
  if (lastNotification) {
    const currentSort = SORT_BY_STRING_MAP[sortBy]
    const currentSortValue = lastNotification[
      currentSort as keyof Notification
    ] as string
    cursor = {
      id: lastNotification.id,
      [currentSort]: currentSortValue || '',
    }
  }
  return cursor
}
