import {
  Typography,
  Stack,
  styled,
  FormControlLabel,
  Switch,
} from '@mui/material'
import { MarkAllReadButton } from './common'
import { useTranslation } from 'react-i18next'
import { useSetNotificationFilter } from '../../../redux/hooks/notification-hooks'
import { useAppSelector } from '../../../redux/hooks/redux-hooks'
import { memo } from 'react'
import { usePatchTableNotificationsMutation } from '../../../redux/slices/api-slice'
import { PatchNotificationReadStateRequest } from '../types/api-types'

interface NotificationHeaderProps {
  notificationIds: string[] // Unread Notification Ids
  setCurrentEndAt: (endAt: number) => void
}
function NotificationHeader({
  notificationIds,
  setCurrentEndAt,
}: NotificationHeaderProps) {
  const { t } = useTranslation()
  // Optimization: UI feedback on isLoading, Error Handling
  const [markAllRead, { isLoading }] = usePatchTableNotificationsMutation()
  const markAllReadRequest: PatchNotificationReadStateRequest = {
    id: notificationIds,
    readState: true,
  }
  const { setNotificationFilter } = useSetNotificationFilter()
  const readState = useAppSelector((state) => state.notification.readState)
  const checked = readState === 'UNREAD'

  const handleReadUnreadChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedReadState = event.target.checked ? 'UNREAD' : 'ALL'
    setNotificationFilter({
      readState: updatedReadState,
      page: 0, // This is to fix racing condition with table pagination modal change
    })
    setCurrentEndAt(Date.now())
  }

  return (
    <NotificationHeaderContainer>
      <Typography variant="h4">
        {' '}
        {t('notification.allNotifications')}{' '}
      </Typography>{' '}
      <Stack gap="8px" flexDirection="row" alignContent="center">
        <MarkAllReadButton
          onClick={() =>
            notificationIds.length > 0 && markAllRead(markAllReadRequest)
          }
        />
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={handleReadUnreadChange}
              color="primary"
            />
          }
          label={t('notification.showUnreadOnly')}
          labelPlacement="end"
        />
      </Stack>
    </NotificationHeaderContainer>
  )
}

export default memo(NotificationHeader)

const NotificationHeaderContainer = styled(Stack)(() => ({
  padding: '24px 32px',
  textColor: '#202020',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))
