import type { Notification } from './api-types'

export interface NotificationCenterRow {
  id: string
  title: string
  createdAt: string
  message: string
  product: number
  type: string
  readState: boolean
  link?: string
  linkName?: string
}

export interface NotificationTableRow {
  id: string
  title: string
  message: string
  deliveryDate: string
  product: number
  sender: string
  type: string
  readState: boolean
  link?: string
  linkName?: string
}

export interface NotificationState {
  message: string
  startFrom: number
  endAt: number
  product: number
  sender: string
  type: string
  readState: 'ALL' | 'UNREAD'
  sortBy: 'CREATED_AT' | 'PRODUCT' | 'TYPE'
  order: 'ASC' | 'DESC'
  limit: number
  page: number
  tableNotifications: Record<number, Notification[]>
  tableCursor: Record<string, string>
}

export interface NotificationFilter
  extends Omit<NotificationState, 'tableNotifications' | 'tableCursor'> {}

export enum NotificationActionType {
  System = 'system',
  Assigned = 'assigned',
  Mentioned = 'mentioned',
  Informational = 'informational',
  Alert = 'alert',
  ActionRequired = 'action_required',
  Reminder = 'reminder',
}
