import { Stack, Typography } from '@mui/material'

interface NotificationBannerProps {
  icon: JSX.Element
  width?: string
  height?: string
  description: string
}

export default function NotificationBanner({
  icon,
  width,
  height,
  description,
}: NotificationBannerProps) {
  return (
    <Stack
      padding="48px"
      width={width || '600px'}
      height={height || 'auto'}
      spacing={3}
      alignItems="center"
      justifyContent="center"
    >
      {icon}
      <Typography variant="h6">{description}</Typography>
    </Stack>
  )
}
