import React from 'react'

interface Props {
  color?: string
  width?: string | number
  height?: string | number
}

function SelectControl({
  color = '#202020',
  width = '18',
  height = '18',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1962 7.5L8.59808 4.5L6 7.5H11.1962ZM11.1962 10.5L8.59808 13.5L6 10.5H11.1962Z"
        fill={color}
        fillOpacity="0.5"
      />
    </svg>
  )
}

export default SelectControl
